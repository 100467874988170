import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {CampaignModel, CampaignState} from "@/stores/campaigns/types";
import {ROUTES} from "@/constants/routes";
import {QuicktippModel} from "@/stores/quicktipps/types";
import {CampaignType, ModelCampaignType} from "@/enum/CampaignType";

const getDefaultState = () => {
    return {
        campaigns: [],
        dialogState: {
            isNew: false,
            type: CampaignType.Campaign,
            campaign: null,
            quicktipps: [],
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<CampaignState, RootState> = {
    getAll({commit}) {
        api.get(ROUTES.CAMPAIGNS_GET_ALL)
            .then((response: AxiosResponse) => {
                commit('setCampaigns', response);
                if (state.dialogState.visible) {
                    commit('dismissCampaignDialog', state);
                }
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    getSingular({commit}, {campaign_id}: { campaign_id: string }) {
        api.get(ROUTES.CAMPAIGNS_GET_SINGLE.replace('{campaign_id}', campaign_id))
            .then((response: AxiosResponse) => {
                commit('setCampaigns', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    create({commit, dispatch}, data: CampaignModel): Promise<any> {
        return new Promise((resolve, reject) => {
            api.post(ROUTES.CAMPAIGNS_CREATE_NEW, data)
                .then((response: AxiosResponse) => {
                    resolve(response)
                })
                .catch((reason) => {
                    if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                        commit('info/error', reason.description, {root: true})
                    } else {
                        commit('info/error', reason, {root: true})
                    }
                    reject()
                })
        })
    },

    uploadImageFile({commit, dispatch}, data: { campaign_id: number; image: FormData }): Promise<any> {
        return new Promise((resolve, reject) => {
            api.post(ROUTES.CAMPAIGNS_IMAGES_CREATE_NEW.replace('{campaign_id}', String(data.campaign_id)), data.image).then(res => {
                resolve(res);
            }).catch(err => {
                if (Object.prototype.hasOwnProperty.call(err, 'description')) {
                    commit('info/error', err.description, {root: true})
                } else {
                    commit('info/error', err, {root: true})
                }
                reject()
            })
        })
    },

    update({commit, dispatch}, data: CampaignModel): Promise<any> {
        //delete campaign id from the request data
        const campaignId = data.id;
        // @ts-ignore TS2790: The operand of a 'delete' operator must be optional.
        delete data.id;

        return new Promise((resolve, reject) => {
            api.put(ROUTES.CAMPAIGNS_UPDATE_EXISTING.replace('{campaign_id}', String(campaignId)), data)
                .then((response: AxiosResponse) => {
                    resolve(true);
                    // commit('info/success', "Kampagne erfolgreich aktualisiert", {root: true});

                    //Remove Campaign Dialog

                    commit('dismissCampaignDialog', state);

                    //reload all Campaigns
                    dispatch("getAll")
                })
                .catch((reason) => {
                    if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                        commit('info/error', reason.description, {root: true})
                    } else {
                        commit('info/error', reason, {root: true})
                    }
                    reject()
                })
        })
    },

    delete({commit, dispatch}, {campaignId}: { campaignId: string }) {
        api.delete(ROUTES.CAMPAIGNS_DELETE_EXISTING.replace('{campaign_id}', campaignId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Kampagne erfolgreich gelöscht", {root: true});

                //reload all Campaigns
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    deleteImage({commit}, data: { campaign_id: number; image_id: number }) {
        api.delete(ROUTES.CAMPAIGNS_IMAGES_DELETE
            .replace('{campaign_id}', String(data.campaign_id))
            .replace('{image_id}', String(data.image_id)))
            .then()
            .catch(err => {
                //console.log(err)
            })
    },

    showInfo({commit}, data: { type: string; msg: string }) {
        commit(data.type, data.msg, {root: true})
    },

    getAllQuicktipps({commit, dispatch}, data: { campaignId: number }) {
        return api.get(ROUTES.CAMPAIGNS_QUICKTIPPS_GET_ALL.replace('{campaign_id}', String(data.campaignId))).then(response => {
            commit('setQuicktipps', response)
        })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    showInformationDialog({commit}, data: { msg: string }) {
        commit('info/error', data.msg, {root: true})
    },
    getAllCampaigns() {
        return new Promise((resolve, reject) => {
            api.get(ROUTES.CAMPAIGNS_GET_ALL)
                .then((response: AxiosResponse) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }
};

// mutations
const mutations: MutationTree<CampaignState> = {
    setCampaigns(state, campaigns: Array<CampaignModel>) {
        state.campaigns = campaigns
    },
    setQuicktipps(state, quicktipps: Array<QuicktippModel>) {
        state.dialogState.quicktipps = quicktipps
    },
    showCampaignDialog(state, data: { type: ModelCampaignType, campaign: CampaignModel | null; isNew: boolean }) {
        state.dialogState.type = data.type
        state.dialogState.campaign = data.campaign;
        state.dialogState.isNew = data.isNew;
        state.dialogState.visible = true
    },
    dismissCampaignDialog(state) {
        state.dialogState.visible = false;
        state.dialogState.campaign = null;
        state.dialogState.isNew = false;
        state.dialogState.quicktipps = [];
    },
    adjustCampaignDialog(state: CampaignState, data: { campaign: CampaignModel | null; isNew: boolean }) {
        state.dialogState.visible = true;
        state.dialogState.campaign = data.campaign;
        state.dialogState.isNew = data.isNew
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
};

export const campaigns: Module<CampaignState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};
