/**
 * Currently not in active use but at least definition of known Dimens
 */
export const DIMENS = {
    CAMPAIGN: {identifier: "campaign"},
    CAMPAIGN_TABLET: {identifier: "campaign_tablet"},
    TICKET_FRONT_IMAGE: {identifier: "front_image"},
    TICKET_BACK_IMAGE: {identifier: "back_image"}
};

export interface Dimension {
    identifier: string;
}

export function getDimensionForName(value: string): Dimension | null {
    switch (value) {
        case DIMENS.CAMPAIGN.identifier:
            return DIMENS.CAMPAIGN;
        case DIMENS.CAMPAIGN_TABLET.identifier:
            return DIMENS.CAMPAIGN_TABLET;
        case DIMENS.TICKET_FRONT_IMAGE.identifier:
            return DIMENS.TICKET_FRONT_IMAGE;
        case DIMENS.TICKET_BACK_IMAGE.identifier:
            return DIMENS.TICKET_BACK_IMAGE;
        default:
            return null
    }
}
