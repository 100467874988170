
import timeUtil from '@/utils/time'
import {Component, Prop, Vue} from "vue-property-decorator";
import {CampaignImage, CampaignModel} from "@/stores/campaigns/types";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {Organization} from "@/enum/Organizations";
import {ImageDimension, OrgConfig} from "@/stores/auth/types";
import {DIMENS} from "@/enum/Dimension";
import AppHyperlink from "@/components/AppHyperlink.vue";
import {getModelCampaignTypeForId} from "@/enum/CampaignType";

const authModule = namespace('auth');
const campaignModule = namespace('campaigns');

@Component({
  name: 'campaign-list-row',
  components: {
    AppHyperlink
  },
})
export default class CampaignListRow extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('imageDimensions') dimens!: Array<ImageDimension> | null;

  @campaignModule.Mutation('showCampaignDialog') showDialog!: Function;
  @campaignModule.Action('delete') deleteCampaign!: Function;

  @Prop({type: Object, required: true, default: {}}) readonly campaign!: CampaignModel;
  // @Prop({type: Number, required: false, default: -1}) readonly position!: number;

  dimenIdPhone = DIMENS.CAMPAIGN.identifier

  get opacity(): string {
    return timeUtil.objectIsActive(this.campaign.start, this.campaign.end) ? '' : 'opacity: 0.5'
  }

  get textClass(): string {
    return timeUtil.objectIsActive(this.campaign.start, this.campaign.end) ? 'black--text' : 'grey--text'
  }

  get CampaignSrc() {
    return this.campaign ? this.getImageIfExisting(DIMENS.CAMPAIGN.identifier)?.url ?? '' : ''
  }

  get typeTranslation() {
    let translation = ''
    switch (this.campaign.category) {
      case 'special':
        translation += 'Sonderprodukt'
        break;
      case 'discount':
        translation += 'Rabattangebot'
        break;
      default:
        translation += 'Standard'
    }
    return translation
  }


  formatDate(date: string): string {
    return timeUtil.formattedDate(date)
  }

  editClicked() {
    this.showDialog(
        {
          type: getModelCampaignTypeForId(this.campaign.category),
          campaign: this.campaign,
          isNew: false
        }
    )
  }

  deleteClicked() {
    QuestionStore.poseQuestion(
        null,
        `Kampagne ${this.campaign.headline} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex: number) => {
      if (answerIndex === 0) {
        this.deleteCampaign({campaignId: this.campaign.id})
      }
    })
  }

  openImage(url: string) {
    window.open(url)
  }

  getImageIfExisting(identifier: string): CampaignImage | undefined {
    return this.campaign.images?.find(e => e.type === identifier)
  }
}
