export const ROLES = {
    ADMIN: {role_id: 1, identifier: "admin", name: "Administrator"},
    QA: {role_id: 2, identifier: "qa", name: "Qualitätssicherung"},
    MARKETING: {role_id: 3, identifier: "marketing", name: "Redaktion"},
    EDITOR: {role_id: 4, identifier: "editor", name: "Editor"},
    SUPERADMIN: {role_id: 5, identifier: "superadmin", name: "SuperAdmin"},
};

export interface Role {
    role_id: number;
    identifier: string;
    name: string;
}

export function getRoleForName(name: string | null): Role | null {
    switch (name) {
        case ROLES.ADMIN.name:
            return ROLES.ADMIN;
        case ROLES.QA.name:
            return ROLES.QA;
        case ROLES.MARKETING.name:
            return ROLES.MARKETING;
        case ROLES.EDITOR.name:
            return ROLES.EDITOR;
        default:
            return null
    }
}

export function getRoleById(id: number): Role | null {
    switch (id) {
        case ROLES.ADMIN.role_id:
            return ROLES.ADMIN;
        case ROLES.QA.role_id:
            return ROLES.QA;
        case ROLES.MARKETING.role_id:
            return ROLES.MARKETING;
        case ROLES.EDITOR.role_id:
            return ROLES.EDITOR;
        default:
            return null
    }
}
