
import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import HomeAppBar from "@/components/AppBar.vue";
import {TicketDialogModel, TicketModel} from "@/stores/tickets/types";
import TicketListRow from "@/components/TicketListRow.vue";
import TicketDialog from "@/components/dialogs/ticket/TicketDialog.vue";
import DocListRow from "@/components/DocListRow.vue";
import DocumentDialog from "@/components/dialogs/document/DocumentDialog.vue";
import {VALUES} from "@/constants/values";
import {DocumentModel} from "@/stores/documents/types";

const ticketModule = namespace('tickets');
const documentsModule = namespace('documents');
const infoModule = namespace('info');

@Component({
  components: {
    TicketDialog,
    TicketListRow,
    HomeAppBar,
    DocumentDialog,
    DocListRow
  },
})
export default class Tickets extends Vue {
  @ticketModule.State('tickets') tickets!: Array<TicketModel>;
  @ticketModule.Action('getAll') fetchTickets!: Function;
  @ticketModule.State('ticketDialogState') DocumentDialog!: TicketDialogModel;
  @ticketModule.Mutation('showTicketDialog') showDialog!: Function;
  @ticketModule.Action('delete') deleteLose!: Function;

  @documentsModule.Mutation('showDocumentDialog') showDocumentDialog!: Function;
  @documentsModule.Action('getAllDynamic') fetchDynamicDocuments!: Function;
  @documentsModule.Action('sortDynamic') sort!: Function;
  @documentsModule.State('dynamicDocuments') allDynamicDocuments!: Array<DocumentModel>;

  @infoModule.Action('error') showMessage!: Function;

  selectedTab = null;
  tabs = ['Lose', 'Dokumente Laufzeiten Lose']

  searchDefinition = '';
  searchDocument = '';

  get itemsWithIndex() {
    // if prices are the same sort by max win, otherwise by price
    let sorted = this.tickets.sort((a: TicketModel, b: TicketModel) => {
      if (a.cent_price === b.cent_price) {
        return (a.cent_hauptgewinn ?? 0) > (b.cent_hauptgewinn ?? 0) ? -1 : 1
      }
      return (a.cent_price ?? 0) > (b.cent_price ?? 0) ? -1 : 1
    })
    // then add index field to items
    return sorted.map(
        (items, index) => ({
          ...items,
          index: index + 1
        })
    )
  }

  get headersLot() {
    return [
      {text: 'Überschrift', align: 'left', value: 'headline', width: '10%', sortable: false},
      {text: 'Beschreibung', align: 'left', value: 'description', sortable: false},
      {text: 'Position', align: 'left', value: 'index', sortable: false},
      {text: 'Preis', align: 'left', value: 'cent_price', sortable: false},
      {text: 'Spitzengewinn', align: 'left', value: 'cent_hauptgewinn', sortable: false},
      {text: 'Chance', align: 'left', value: 'chance_hauptgewinn', sortable: false},
      {text: 'Start', align: 'left', value: 'starttime', sortable: false},
      {text: 'Ende', align: 'left', value: 'endtime', sortable: false},
      {text: 'Optionen', align: 'left', value: 'option', sortable: false}
    ];
  }

  get documents() {
    return this.allDynamicDocuments.filter((item: DocumentModel) => item.category === VALUES.DYNAMIC_DOCUMENT_CATEGORY_LOT_RUNTIMES)
  }

  get headersDocuments() {
    return [
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'Titel', align: 'left', value: 'title', sortable: true},
      {text: 'Datei', align: 'left', value: '', sortable: true},
      {text: 'Verfügbar ab', align: 'left', value: 'publish_at', sortable: true},
      {text: 'Verfügbar bis', align: 'left', value: 'publish_until', sortable: true},
      {text: 'Optionen', align: 'left', value: 'option', sortable: false}
    ];
  }

  reachedDocumentLimit(): boolean {
    return (this.documents.length >= VALUES.DYNAMIC_LOT_RUNTIMES_MAX_COUNT);
  }

  mounted() {
    this.fetchTickets()
    this.fetchDynamicDocuments()
  }

  onNewTicket() {
    this.showDialog(
        {
          los: null,
          isNew: true
        }
    )
  }

  onNewDocument() {
    this.showDocumentDialog(
        {
          preDefinedCategory: VALUES.DYNAMIC_DOCUMENT_CATEGORY_LOT_RUNTIMES,
          document: null,
          isNew: true
        }
    )
  }

  onDropEvent(ids: Array<number | null>) {
    // safety sanitization (of null) to avoid theoretical errors
    this.sort(
        {
          document_ids: ids.filter(Number)
        }
    )
  }
}
