<template>
  <div id="settings-wrapper">
    <v-card
        id="settings"
        class="py-2 px-4"
        color="rgba(0, 0, 0, .3)"
        dark
        flat
        link
        min-width="100"
        style="position: fixed; top: 115px; right: -35px; border-radius: 8px; z-index: 1;"
    >
      <v-icon large>
        mdi-cog
      </v-icon>
    </v-card>

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        activator="#settings"
        bottom
        content-class="v-settings"
        left
        nudge-left="8"
        offset-x
        origin="top right"
        transition="scale-transition"
    >
      <v-card
          class="text-center mb-0"
          width="300"
      >
        <v-card-text>
          <v-row
              align="center"
              no-gutters
          >
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer/>

            <v-col cols="auto">
              <v-switch
                  v-model="$vuetify.theme.dark"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                  @change="saveModeSetting($event)"
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 grey lighten-2"/>

          <v-btn
              block
              class="mb-3"
              color="grey darken-1"
              dark
              href="https://lottoads-test.mobivention.eu/doc"
              rel="noopener"
              target="_blank"
          >
            API Dokumentation
          </v-btn>

          <v-btn
              block
              color="grey darken-1"
              dark
              href="https://vuetifyjs.com/en/introduction/roadmap"
              rel="noopener"
              target="_blank"
          >
            Vuetify
          </v-btn>

          <div class="my-4"/>

        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'SiteSettings',

  data: () => ({
    menu: false
  }),

  watch: {
    color(val) {
      this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
    }
  },
  methods: {
    saveModeSetting(isDarkMode) {
      const mode = isDarkMode ? 'dark' : 'light'
      localStorage.setItem('mode', mode)
    }
  }
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer
</style>
