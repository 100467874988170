
import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import {OrgConfig} from "@/stores/auth/types";

import HomeAppBar from "@/components/AppBar.vue";
import {VALUES} from "@/constants/values";
import {Organization} from "@/enum/Organizations";
import AppFileInput from "@/components/AppFileInput.vue";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {DocumentModel} from "@/stores/documents/types";
import AppHyperlink from "@/components/AppHyperlink.vue";

const authModule = namespace('auth');
const documentsModule = namespace('documents');
const infoModule = namespace('info');

@Component({
  components: {
    AppHyperlink,
    AppFileInput,
    HomeAppBar
  },
})
export default class Campaigns extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @documentsModule.Action('getAll') fetchDocuments!: Function;
  @documentsModule.Action('upload') uploadScreensaver!: Function;
  @documentsModule.Action('create') createScreensaverDocument!: Function;
  @documentsModule.Action('delete') deleteScreensaver!: Function;
  @documentsModule.State('documents') documents!: Array<DocumentModel>;

  @infoModule.Action('error') showError!: Function;
  @infoModule.Action('setLoadingDismissalFlag') setLoadingDismissalFlag!: Function;

  $refs!: {
    screensaver: HTMLInputElement;
  };

  get screensaverDocument(): DocumentModel | undefined {
    return this.documents.find((item: DocumentModel) => item.key === VALUES.SCREENSAVER_DOCUMENT_KEY)
  }

  mounted() {
    this.fetchDocuments()
  }

  askScreensaverUpdate(file: any) {
    // Don't trigger when input is cleared
    if (file) {
      QuestionStore.poseQuestion(
          "Bildschirmschoner Upload",
          `Möchten Sie den Bildschirmschoner jetzt veröffentlichen?`,
          ['Abbrechen', 'Veröffentlichen'],
          -1
      ).then((answerIndex) => {
        if (answerIndex === 1) {
          // screensaver is just another document without time restriction
          this.handleScreensaverUpdate(file)
        }
      })
    }
  }

  handleScreensaverUpdate(file: any) {
    if (this.screensaverDocument) {
      this.deleteScreensaver({documentId: this.screensaverDocument?.document_id}).then(() => {
        this.createNewScreensaver(file)
      })
    } else {
      this.createNewScreensaver(file)
    }
  }

  createNewScreensaver(file: any) {
    this.setLoadingDismissalFlag(true);

    const formData = new FormData();
    formData.append("document", file);

    this.uploadScreensaver(formData).then((res: any) => {
      const data = {
        'key': VALUES.SCREENSAVER_DOCUMENT_KEY,
        'filename': res.filename,
        'title': 'notEmpty',
        'category': 'video',
        'publish_at': null,
        'publish_until': null
      };
      this.createScreensaverDocument(data).then(() => {
        this.setLoadingDismissalFlag(false);
      })
    }).catch((err: any) => {
      this.showError('Screensaver konnte nicht gespeichert werden')
    })
  }
}
