export default {
    state: {
        currentTitle: '',
        currentQuestion: '',
        currentAnswers: [''],
        cancelIndex: -1,
        _questionResolve: ((val: number) => {
            //something
        })
    },
    poseQuestion(title: string | null, text: string, answers: string[], cancelIndex: number | undefined) {
        this.state.currentTitle = title ?? '';
        this.state.currentQuestion = text;
        this.state.currentAnswers = answers;
        this.state.cancelIndex = cancelIndex !== undefined ? cancelIndex : -1;
        return new Promise<number>((resolve) => {
            this.state._questionResolve = resolve
        })
    },
    resolveQuestion(answer: number) {
        this.state._questionResolve(answer);
        this.state.currentTitle = '';
        this.state.currentQuestion = '';
        this.state.currentAnswers = ['']
    }
}
