export const CampaignType = {
    Campaign: {name: "Standard", serverId: "campaign"},
    Special: {name: "Sonderprodukte", serverId: "special"},
    Discount: {name: "Rabattangebote", serverId: "discount"}
}

export function getModelCampaignTypeForId(id: string | null): ModelCampaignType {
    switch (id) {
        case CampaignType.Special.serverId:
            return CampaignType.Special
        case CampaignType.Discount.serverId:
            return CampaignType.Discount
        default:
            return CampaignType.Campaign
    }
}

export interface ModelCampaignType {
    name: string,
    serverId: string
}
