import {Product, PRODUCTS} from "@/enum/Products";
import {GameType, GAMETYPES} from "@/enum/GameTypes";
import {DocumentsDefinition} from "@/stores/documents/types";

export function createMapForOrgMaxFields(orgCode: number, isOnline: boolean): Map<GameType, number> {
    let map: Map<GameType, number> = new Map();

    switch (orgCode) {
        case 2: //ORGANIZATIONS.BY.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 6]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 6]
                ]);
            }
            break;
        case 3: //ORGANIZATIONS.BW.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 6]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 6]
                ]);
            }
            break;
        case 13: //ORGANIZATIONS.BB.validCodes: [] //DONE
            //BB Terminal is defined as service Product
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 18],
                    [GAMETYPES.LOTTO_SYSTEM, 4],
                    [GAMETYPES.EUROJACKPOT, 15],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 6: //ORGANIZATIONS.RP.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 12: //ORGANIZATIONS.SL.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 8: //ORGANIZATIONS.SH.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 11: //ORGANIZATIONS.ST.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 12],
                    [GAMETYPES.EUROJACKPOT, 10]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 14]
                ]);
            }
            break;
        case 7: //ORGANIZATIONS.BE.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 8]
                ]);
            }
            break;
        case 16: //ORGANIZATIONS.MV.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 12],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
    }
    return map
}

export function createMapForOrgMaxRuntimes(orgCode: number, isOnline: boolean): Map<GameType, number> {
    let map: Map<GameType, number> = new Map();

    switch (orgCode) {
        case 2: //ORGANIZATIONS.BY
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 3: //ORGANIZATIONS.BW
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 13: //ORGANIZATIONS.BB
            //BB Terminal is defined as service Product
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 5],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 6: //ORGANIZATIONS.RP
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 12: //ORGANIZATIONS.SL
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 8: //ORGANIZATIONS.SH
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 11: //ORGANIZATIONS.ST
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52]
                ]);
            }
            break;
        case 7: //ORGANIZATIONS.BE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 10],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 10],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 16: //ORGANIZATIONS.MV
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 5],
                    [GAMETYPES.EUROJACKPOT, 5],
                    [GAMETYPES.GLUECKSSPIRALE, 5],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
    }
    return map
}

export const ORGANIZATIONS = {
    BB: {
        validCodes: new Map([
            [PRODUCTS.SVA, 13],
        ]),
        name: "Land Brandenburg Lotto GmbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(13, false),
                        runtimes: createMapForOrgMaxRuntimes(13, false)
                    }
                ]
            ]
        ),
        //see stores/documents/types.ts DocumentsDefinition, DocumentCategory, DocumentType
        documents: {
            availableCategories: [
                {
                    title: "Spielinfo",
                    serverValue: "Spielinfo",
                    validTypes: [
                        {
                            key: "SI1",
                            title: "LOTTO 6aus49"
                        },
                        {
                            key: "SI2",
                            title: "Eurojackpot"
                        },
                        {
                            key: "SI3",
                            title: "GlücksSpirale"
                        },
                        {
                            key: "SI4",
                            title: "KENO"
                        },
                        {
                            key: "SI5",
                            title: "TOTO Auswahlwette"
                        },
                        {
                            key: "SI6",
                            title: "TOTO Ergebniswette"
                        },
                        {
                            key: "SI7",
                            title: "Zusatzlotterien"
                        }
                    ]
                },
                {
                    title: "Spielerschutz",
                    serverValue: "Spielerschutz",
                    validTypes: [
                        {
                            key: "S1",
                            title: "Prävention hat Priorität"
                        },
                        {
                            key: "S2",
                            title: "Jugendschutz"
                        },
                        {
                            key: "S3",
                            title: "Spielersperren und Kundenkartenpflicht"
                        },
                        {
                            key: "S4",
                            title: "Beratung und Hilfe"
                        },
                        {
                            key: "S6",
                            title: "Flyer Spielerschutz"
                        },
                        {
                            key: "S5",
                            title: "Fragebogen Spielerschutz"
                        }
                    ]
                },
                {
                    title: "Teilnahmebedingungen",
                    serverValue: "Teilnahmebedingungen",
                    validTypes: [
                        {
                            key: "T1",
                            title: "LOTTO 6aus49"
                        },
                        {
                            key: "T2",
                            title: "Eurojackpot"
                        },
                        {
                            key: "T3",
                            title: "GlücksSpirale"
                        },
                        {
                            key: "T4",
                            title: "KENO"
                        },
                        {
                            key: "T5",
                            title: "Spiel 77"
                        },
                        {
                            key: "T6",
                            title: "SUPER 6"
                        },
                        {
                            key: "T7",
                            title: "Die Sieger-Chance"
                        },
                        {
                            key: "T8",
                            title: "plus 5"
                        },
                        {
                            key: "T9",
                            title: "Dauerspiel"
                        },
                        {
                            key: "T10",
                            title: "Lose"
                        },
                        {
                            key: "T11",
                            title: "Glücksrakete"
                        },
                        {
                            key: "T12",
                            title: "TOTO 13er Ergebniswette"
                        },
                        {
                            key: "T13",
                            title: "TOTO 6aus45 Auswahlwette"
                        }
                    ]
                },
                // This is a selectable category that switches DocumentDialog into "dynamic" UI
                {
                    title: "Sonderauslosung (dynamische ID-Vergabe)",
                    serverValue: "Sonderauslosung",
                    validTypes: []
                },
                {
                    title: "Broschüren",
                    serverValue: "Broschüren",
                    validTypes: [
                        {
                            key: "B1",
                            title: "Produktbroschüre"
                        },
                        {
                            key: "B2",
                            title: "LOTTO 6aus49 System"
                        },
                        {
                            key: "B3",
                            title: "TOTO Auswahlwette System"
                        },
                        {
                            key: "B4",
                            title: "TOTO Ergebniswette System"
                        }
                    ]
                },
                {
                    title: "Weitere Dokumente",
                    serverValue: "Weitere Dokumente",
                    validTypes: [
                        {
                            key: "D1",
                            title: "Datenschutzerklärung"
                        },
                        {
                            key: "I1",
                            title: "Impressum"
                        },
                        {
                            key: "W1",
                            title: "Kontakt"
                        },
                        {
                            key: "W2",
                            title: "Kundenkarten"
                        },
                        {
                            key: "W3",
                            title: "Dauerspiel"
                        },
                        {
                            key: "W4",
                            title: "Gewinne abholen"
                        },
                        {
                            key: "W5",
                            title: "Über LOTTO Brandenburg"
                        }
                    ]
                },
                // Following categories or not actively selectable
                {
                    title: "Laufzeiten Lose (dynamische ID-Vergabe)",
                    serverValue: "lot",
                    validTypes: []
                },
            ]
        }
    },
};

export interface Organization {
    validCodes: Map<Product, number>;
    name: string;
    siteConfig: Map<Product, OnSiteConfig>;
    documents: DocumentsDefinition | null;
}

export interface OnSiteConfig {
    fields: Map<GameType, number>;
    runtimes: Map<GameType, number>;
}

export function getOrgForCode(value: number, productIdentifier: string): Organization {
    return ORGANIZATIONS.BB
}

export function getCodeForOrganizationAndProduct(org: Organization, productIdentifier: string, variant: string): number | undefined {
    return ORGANIZATIONS.BB.validCodes.get(PRODUCTS.SVA)
}
