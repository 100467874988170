
import Vue from "vue";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {Component} from "vue-property-decorator";

@Component({
  name: 'QuestionDialog'
})
export default class QuestionDialog extends Vue {
  state = QuestionStore.state;

  chooseAnswer(idx: number) {
    QuestionStore.resolveQuestion(idx)
  }

  dialogInput() {
    QuestionStore.resolveQuestion(this.state.cancelIndex)
  }
}
