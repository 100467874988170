
import {Component} from "vue-property-decorator";
import Vue from "vue";
import ReportDialog from "@/components/dialogs/report/ReportDialog.vue";
import ReportListRow from "@/components/ReportListRow.vue";
import {namespace} from "vuex-class";
import {ReportModel} from "@/stores/reports/types";

const reportsModule = namespace('reports');

@Component({
  name: 'Reports.vue',
  components: {
    ReportListRow,
    ReportDialog
  }
})
export default class Reports extends Vue {
  @reportsModule.Action('getAll') fetchReports!: Function;
  @reportsModule.State('reports') reports!: Array<ReportModel>;

  search = '';
  footerProps = {
    'items-per-page-options': [20, 50, 100, -1]
  };

  get headers() {
    return [
      //Adjusted for BB, how to incorporate multiple images, quicktipps in overall display?
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'Typ', align: 'left', value: 'type', sortable: true},
      {text: 'Geräteidentifikation', align: 'left', value: 'device_id', sortable: true},
      {text: 'Datum', align: 'left', value: 'reported_at', sortable: true},
      {text: 'Optionen', align: 'center', value: 'option', sortable: false}
    ];
  }

  mounted() {
    this.fetchReports()
  }
}
