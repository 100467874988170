import {VALUES} from "@/constants/values";

export interface DocumentModel {
    document_id: number | null;
    filename: string | null;
    src: string | null;
    is_active: boolean | null;
    key: string | null;
    title: string | null;
    category: string | null;
    prio: number | null;
    publish_at: string | null;
    publish_until: string | null;
    // dynamic exclusive
    user_filename: string | null;
    comment: string | null;
}

export interface DynamicDocRequestModel {
    publish_at: string | null;
    publish_until: string | null;
    document_base64: string | null;
    title: string | null;
    category: string | null;
    comment: string | null;
    user_filename: string | null;
}

//Dialog exclusive
export interface DocumentDialogModel {
    isNew: boolean;
    preDefinedCategoryServerId: string | null;
    document: DocumentModel | null;
    visible: boolean;
}

export interface DocumentsDefinition {
    availableCategories: Array<DocumentCategory>;
}

export interface DocumentCategory {
    title: string; // should be same as serverValue in most case
    serverValue: string;
    validTypes: DocumentType[];
}

export interface DocumentType {
    key: string;
    title: string;
}

export interface DocumentState {
    documents: Array<DocumentModel>;
    dynamicDocuments: Array<DocumentModel>;
    docDialogState: DocumentDialogModel;
}

export const isDynamicDoc = (
    document: DocumentModel
) => {
    return (document.key?.startsWith(VALUES.DYNAMIC_DOCUMENT_PREFIX_LOT_RUNTIMES) || document.key?.startsWith(VALUES.DYNAMIC_DOCUMENT_PREFIX_TB_SPECIAL))
}
