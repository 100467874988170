
import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import {CampaignDialogModel, CampaignModel} from "@/stores/campaigns/types";
import {OrgConfig} from "@/stores/auth/types";

import HomeAppBar from "@/components/AppBar.vue";
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import CampaignDialog from "@/components/dialogs/campaign/CampaignDialog.vue";
import CampaignListRow from "@/components/campaigns/CampaignListRow.vue";
import {VALUES} from "@/constants/values";
import {Organization} from "@/enum/Organizations";
import timeUtil from '@/utils/time'
import {CampaignType, ModelCampaignType} from "@/enum/CampaignType";
import AppFileInput from "@/components/AppFileInput.vue";
import AppHyperlink from "@/components/AppHyperlink.vue";

const authModule = namespace('auth');
const campaignModule = namespace('campaigns');
const infoModule = namespace('info');

@Component({
  components: {
    AppHyperlink,
    AppFileInput,
    HomeAppBar,
    CampaignDialog,
    VDatetimePicker,
    CampaignListRow
  },
})
export default class Campaigns extends Vue {
  //https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html#non-null-assertion-operator
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @campaignModule.Action('getAll') fetchCampaigns!: Function;
  @campaignModule.State('campaigns') allCampaigns!: Array<CampaignModel>;
  @campaignModule.State('dialogState') campaignDialog!: CampaignDialogModel;
  @campaignModule.Mutation('showCampaignDialog') showDialog!: Function;

  @infoModule.Action('error') showError!: Function;

  campaignTypes: Array<ModelCampaignType> = [CampaignType.Campaign, CampaignType.Special, CampaignType.Discount]
  search = '';

  $refs!: {
    screensaver: HTMLInputElement;
  };

  // TODO maybe migration on database for all at time of migration defined campaigns to get 'campaign' category id -> ??
  get campaings(): Array<CampaignModel> {
    return this.allCampaigns.filter(model => (model.category == CampaignType.Campaign.serverId || !model.category))
  }

  get specials(): Array<CampaignModel> {
    return this.allCampaigns.filter(model => model.category == CampaignType.Special.serverId)
  }

  get discounts(): Array<CampaignModel> {
    return this.allCampaigns.filter(model => model.category == CampaignType.Discount.serverId)
  }

  get displayCampaigns(): Array<CampaignModel> {
    if (this.search.length !== 0) {
      return this.filteredCampaigns();
    } else {
      return this.allCampaigns;
    }
  }

  get headersCampaign() {
    const headerArray: Object[] = [];
    headerArray.push({text: 'Vorschau (Bild)', align: 'left', value: '', width: '20%', sortable: false});
    headerArray.push({text: 'Kategorie', align: 'left', value: 'category', width: '20%', sortable: false});
    headerArray.push({text: 'Start', align: 'left', value: 'start', width: '26%', sortable: false});
    headerArray.push({text: 'Ende', align: 'left', value: 'end', width: '28%', sortable: false});
    headerArray.push({text: 'Optionen', align: 'left', value: 'option', width: '6%', sortable: false});
    return headerArray;
  }

  filteredCampaigns() {
    const searchTerm = this.search.toLowerCase();

    console.log('DEBUG1', searchTerm)

    return this.allCampaigns.filter((campaign: CampaignModel) => {
      const translatedTerm = this.typeTranslation(campaign.category).toLowerCase()
      console.log('DEBUG2', `result for campaign with id ${campaign.id} and translated cat ${translatedTerm} -> ${translatedTerm.includes(searchTerm)}`)
      return translatedTerm.includes(searchTerm)
    });
  }

  typeTranslation(campCat: string | null) {
    let translation = ''
    switch (campCat) {
      case 'special':
        translation += 'Sonderprodukt'
        break;
      case 'discount':
        translation += 'Rabattangebot'
        break;
      default:
        translation += 'Kampagne'
    }
    return translation
  }

  /**
   * Sort based on startTime (undefined is valued as 0 millis) with most recent startTime first
   * @param items
   */
  customSort(items: Array<CampaignModel>): Array<CampaignModel> {
    return items.sort((a: CampaignModel, b: CampaignModel) => {
      return timeUtil.parseToMillis(a.start) > timeUtil.parseToMillis(b.start) ? -1 : 1
    })
  }


  reachedCampaignLimit(): boolean {
    return (this.campaings.length >= VALUES.CAMPAIGNS_MAX_COUNT);
  }

  mounted() {
    this.fetchCampaigns()
  }

  onNewCampaign(selectedType: ModelCampaignType) {
    this.showDialog(
        {
          type: selectedType,
          campaign: null,
          isNew: true
        }
    )
  }


}
