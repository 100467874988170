import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/stores/types";
import {InfoState} from "@/stores/info/types";
import {DIALOG_TYPES} from "@/constants/dialogTypes";

const getDefaultState = () => {
    return {
        type: null,
        title: null,
        message: null,
        dialogVisible: false,
        snackbarVisible: false,
        loading: false,
        preventLoadingDismissal: false
    }
};

const state = getDefaultState();

const actions: ActionTree<InfoState, RootState> = {
    success({commit}, message: string) {
        commit('success', message);
    },
    error({commit}, message: string) {
        commit('error', message);
    },
    clear({commit}) {
        commit('clear');
    },
    setLoadingDismissalFlag({commit}, active: boolean) {
        commit('setPreventLoadingDismissalFlag', active);
    }
};

const mutations: MutationTree<InfoState> = {
    success(state, message: string) {
        state.type = DIALOG_TYPES.SUCCESS;
        state.title = "Info";
        state.message = message;

        // If we want to switch what we use for error display, change it here
        state.snackbarVisible = true;
        state.dialogVisible = false;
    },
    error(state, message: string) {
        state.type = DIALOG_TYPES.ERROR;
        state.title = "Hinweis";
        state.message = message;

        // If we want to switch what we use for error display, change it here
        state.snackbarVisible = false;
        state.dialogVisible = true;
    },
    errorSnackbar(state, message: string) {
        state.type = DIALOG_TYPES.ERROR;
        state.title = "Hinweis";
        state.message = message;
        state.snackbarVisible = true;
        state.dialogVisible = false;
    },
    clear(state) {
        state.type = null;
        state.title = null;
        state.message = null;
        state.dialogVisible = false;
        state.snackbarVisible = false;
        state.preventLoadingDismissal = false;
    },
    setLoading(state, isLoading: boolean) {
        state.loading = isLoading
    },
    setPreventLoadingDismissalFlag(state, active: boolean) {
        state.preventLoadingDismissal = active
    }
};

export const getters: GetterTree<InfoState, RootState> = {
    dialogColorForType(state): string | null {
        let color = null;
        switch (state.type) {
            case DIALOG_TYPES.SUCCESS:
                color = "#00FF00";
                break;
            case DIALOG_TYPES.ERROR:
                color = "#FF0000";
                break
        }
        return color
    },
    dialogTitle(state): string | null {
        return state.title
    },
    dialogMessage(state): string | null {
        return state.message
    }
};

export const info: Module<InfoState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
