import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {TicketModel, TicketState} from "@/stores/tickets/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        tickets: [],
        ticketDialogState: {
            isNew: false,
            ticket: null,
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<TicketState, RootState> = {
    getAll({commit}) {
        api.get(ROUTES.TICKETS_GET_ALL)
            .then((response: AxiosResponse) => {
                commit('setTickets', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    create({commit, dispatch}, data: TicketModel) {
        api.post(ROUTES.TICKETS_CREATE_NEW, data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Los erfolgreich angelegt", {root: true});

                //Remove DocDialog
                commit('dismissTicketDialog', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    update({commit, dispatch}, data: { formData: FormData; lot_id: string }) {
        api.put(ROUTES.TICKETS_UPDATE_EXISTING.replace('{lot_id}', data.lot_id), data.formData)
            .then((response: AxiosResponse) => {
                commit('info/success', "Los erfolgreich bearbeitet", {root: true});

                //Remove DocDialog
                commit('dismissTicketDialog', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    deleteImage({commit, dispatch}, data: { lot_id: string; image: string }) {
        api.delete(ROUTES.TICKETS_DELETE_IMAGE.replace('{lot_id}', data.lot_id).replace('{image}', data.image))
            .then((response: AxiosResponse) => {
                commit('info/success', "Bild erfolgreich bearbeitet", {root: true})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    delete({commit, dispatch}, {loseId}: { loseId: string }) {
        api.delete(ROUTES.TICKETS_DELETE_EXISTING.replace('{lot_id}', loseId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Los erfolgreich gelöscht", {root: true});

                //@TODO
                //Delete Images aswell

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
}

// mutations
const mutations: MutationTree<TicketState> = {
    showTicketDialog(state, data: { los: TicketModel | null; isNew: boolean }) {
        state.ticketDialogState.ticket = data.los;
        state.ticketDialogState.isNew = data.isNew;
        state.ticketDialogState.visible = true
    },
    dismissTicketDialog(state) {
        state.ticketDialogState.visible = false;
        state.ticketDialogState.ticket = null;
        state.ticketDialogState.isNew = false
    },
    setTickets(state, tickets: Array<TicketModel>) {
        state.tickets = tickets
    },
};

export const tickets: Module<TicketState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};
