
import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {Organization} from "@/enum/Organizations";
import {QuicktippModel} from "@/stores/quicktipps/types";
import {QuicktippType} from "@/enum/QuicktippTypes";
import {DRAWDAYS} from "@/enum/DrawDays";

const authModule = namespace('auth');
const quicktippModule = namespace('quicktipps');

@Component({
  name: 'quicktipp-list-row'
})
export default class QuicktippListRow extends Vue {
  @authModule.Getter('gesellschaft') organization!: Organization;

  @quicktippModule.Mutation('showQuicktippsDialog') showDialog!: Function;
  @quicktippModule.Action('delete') deleteQuicktipp!: Function;

  @Prop({type: Object, required: true, default: {}}) readonly quicktipp!: QuicktippModel;

  dayOfNextDrawId = DRAWDAYS.DAY_OF_NEXT_DRAW.identifier

  editClicked() {
    this.showDialog({
      quicktipp: this.quicktipp,
      isNew: false,
      context: QuicktippType.SIMPLE.identifier,
      campaignId: null
    })
  }

  deleteClicked() {
    QuestionStore.poseQuestion(
        null,
        `Quicktipp wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex: number) => {
      if (answerIndex === 0) {
        this.deleteQuicktipp({quicktippId: this.quicktipp.id})
      }
    })
  }
}
