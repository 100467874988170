export const QuicktippType = {
    SIMPLE: {identifier: 'simple'},
    CAMPAIGN: {identifier: 'campaign'},
};

export interface QuicktippType {
    identifier: string;
}

export function getQuicktippTypeForName(value: string): QuicktippType | null {
    switch (value) {
        case QuicktippType.SIMPLE.identifier:
            return QuicktippType.SIMPLE;
        case QuicktippType.CAMPAIGN.identifier:
            return QuicktippType.CAMPAIGN;
        default:
            return null
    }
}
