
import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import DocumentDialog from "@/components/dialogs/document/DocumentDialog.vue";
import HomeAppBar from "@/components/AppBar.vue";
import DocListRow from '@/components/DocListRow.vue';
import {DocumentModel} from "@/stores/documents/types";
import {VALUES} from "@/constants/values";

const documentsModule = namespace('documents');

@Component({
  components: {
    HomeAppBar,
    DocumentDialog,
    DocListRow
  },
})
export default class Documents extends Vue {
  @documentsModule.Action('getAll') fetchDocuments!: Function;
  @documentsModule.Action('getAllDynamic') fetchDynamicDocuments!: Function;
  @documentsModule.Action('sortDynamic') sort!: Function;

  @documentsModule.State('documents') documents!: Array<DocumentModel>;
  @documentsModule.State('dynamicDocuments') allDynamicDocuments!: Array<DocumentModel>;

  @documentsModule.Mutation('showDocumentDialog') showDialog!: Function;
  //@documentsModule.Action('delete') deleteDocument!: Function

  selectedTab = 0;
  tabs = ['Spielinfo', 'Spielerschutz', 'Service']

  tabsService = ['Teilnahmebedingungen', 'Sonderauslosungen', 'Broschüren', 'Weitere Dokumente']
  selectedSubCategoryService = 0;

  // We reuse one shared search which we reset on changing display table
  // reduces redundant variables as the layout already defines small tables
  search = '';

  // Takes the 'serverValue' of category displayed
  // see Organizations.ts 'availableCategories' for definition
  categoryApiValue = 'Spielinfo'

  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  // same for each table of this page
  get headers() {
    const headerArr = [
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'Titel', align: 'left', value: 'title', sortable: false},
    ]
    // Sonderauslosungen
    if ((this.selectedTab === 2) && this.selectedSubCategoryService == 1) {
      headerArr.push({text: 'Datei', align: 'left', value: '', sortable: false})
    }
    headerArr.push({text: 'Verfügbar ab', align: 'left', value: 'publish_at', sortable: false})
    headerArr.push({text: 'Verfügbar bis', align: 'left', value: 'publish_until', sortable: false})
    headerArr.push({text: 'Optionen', align: 'left', value: 'option', sortable: false})
    return headerArr
  }

  // For definition of here used prefixes see Organizations.ts availableCategories
  get documentsGameInfo() {
    return this.documents.filter((doc: DocumentModel) => doc.key?.startsWith('SI'))
  }

  get documentsPlayerProtection() {
    return this.documents.filter((doc: DocumentModel) => doc.key?.startsWith('S') && !doc.key?.startsWith('SI'))
  }

  get documentsConditions() {
    return this.documents.filter((doc: DocumentModel) =>
        doc.key?.startsWith('T')
    )
  }

  get documentsSpecialConditions() {
    return this.allDynamicDocuments.filter((doc: DocumentModel) =>
        doc.key?.startsWith(VALUES.DYNAMIC_DOCUMENT_PREFIX_TB_SPECIAL)
    )
  }

  get documentsBroschures() {
    return this.documents.filter((doc: DocumentModel) => doc.key?.startsWith('B'))
  }

  get documentsMisc() {
    return this.documents.filter((doc: DocumentModel) =>
        doc.key?.startsWith('D') || doc.key?.startsWith('I') || doc.key?.startsWith('W')
    )
  }

  @Watch('selectedTab')
  onSelectedTabChanged(index: number | undefined) {
    this.search = ''
    switch (index) {
      case 0:
        this.categoryApiValue = 'Spielinfo'
        break;
      case 1:
        this.categoryApiValue = 'Spielerschutz'
        break;
      case 2:
        this.setCategoryApiValueForService(this.selectedSubCategoryService)
        break;
    }
  }

  @Watch('selectedSubCategoryService')
  onServiceSubCategoryChanged(index: number) {
    this.search = ''
    this.setCategoryApiValueForService(index)
  }

  setCategoryApiValueForService(selectedChipIndex: number) {
    switch (selectedChipIndex) {
      case 0:
        this.categoryApiValue = 'Teilnahmebedingungen'
        break;
      case 1:
        this.categoryApiValue = 'Sonderauslosung'
        break;
      case 2:
        this.categoryApiValue = 'Broschüren'
        break;
      case 3:
        this.categoryApiValue = 'Weitere Dokumente'
        break;
    }
  }

  mounted() {
    this.fetchDocuments()
    this.fetchDynamicDocuments()
  }

  onNewDocument() {
    this.showDialog(
        {
          document: null,
          preDefinedCategory: this.categoryApiValue,
          isNew: true
        }
    )
  }

  onDropEvent(ids: Array<number | null>) {
    // safety sanitization (of null) to avoid theoretical errors
    this.sort(
        {
          document_ids: ids.filter(Number)
        }
    )
  }

  applyDefinedConditionSorting(items: DocumentModel[]): DocumentModel[] {
    const sortOrder = ['T1', 'T2', 'T3', 'T4', 'T13', 'T12', 'T7', 'T8', 'T5', 'T6', 'T10', 'T9', 'T11']; // Sort order for document types
    function sortByDefinedOrder(a: DocumentModel, b: DocumentModel): number {
      const keyA = a.key ?? ''; // Fallback - empty string if null
      const keyB = b.key ?? ''; // Fallback - empty string if null

      const indexA = sortOrder.indexOf(keyA);
      const indexB = sortOrder.indexOf(keyB);

      if (indexA === -1) return 1; // Unknown items at the end
      if (indexB === -1) return -1; // Unknown items at the end

      return indexA - indexB; // Sort by index in sortOrder
    }

    return items.slice().sort(sortByDefinedOrder); // Returning sorted copy of items
  }
}
