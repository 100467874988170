
import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {CampaignDialogModel, CampaignImage, CampaignModel} from "@/stores/campaigns/types";
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import QuicktippsDialog from "@/components/dialogs/QuicktippsDialog.vue";
import AppFileInput from "@/components/AppFileInput.vue";
import AppHyperlink from "@/components/AppHyperlink.vue";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import timeUtil from "@/utils/time"
import {VALUES} from "@/constants/values";
import {ImageDimension, OrgConfig, User} from "@/stores/auth/types";
import {Organization} from "@/enum/Organizations";
import {DIMENS, Dimension} from "@/enum/Dimension";
import {DEEPLINK} from "@/enum/Deeplinks";
import {QuicktippModel} from "@/stores/quicktipps/types";
import {QuicktippType} from "@/enum/QuicktippTypes";
import {ROLES} from "@/enum/Roles";
import moment from "moment";
import {CampaignType} from "@/enum/CampaignType";
import {toBase64} from "@/utils/misc";
import {AcceptFlag} from "@/enum/AcceptFlag";

const authModule = namespace('auth');
const campaignsModule = namespace('campaigns');
const quicktippsModule = namespace('quicktipps');
const infoModule = namespace('info');

@Component({
  name: 'CampaignDialog',
  components: {
    VDatetimePicker,
    QuicktippsDialog,
    AppFileInput,
    AppHyperlink
  },
})
export default class CampaignDialog extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('user') user!: User;
  @authModule.Getter('imageDimensions') dimens!: Array<ImageDimension> | null;

  @campaignsModule.Action('getAll') fetchCampaigns!: Function;
  @campaignsModule.State('dialogState') state!: CampaignDialogModel;

  @campaignsModule.Action('update') updateCampaign!: Function;
  @campaignsModule.Action('create') createCampaign!: Function;
  @campaignsModule.Action('uploadImageFile') uploadImage!: Function;
  @campaignsModule.Action('deleteImage') deleteImage!: Function;
  @campaignsModule.Action('showInfo') showMessage!: Function;

  @infoModule.Action('setLoadingDismissalFlag') setLoadingDismissalFlag!: Function;

  // Array of Quicktipp inside given campaign object
  @campaignsModule.Action('getAllQuicktipps') fetchQuicktipps!: Function;

  @campaignsModule.Action('showInformationDialog') showInformation!: Function;
  @campaignsModule.Mutation('adjustCampaignDialog') adjustDialog!: Function;
  @campaignsModule.Getter('dialogMessage') message!: string | null;
  @campaignsModule.Action('getAllCampaigns') getAllCampaigns!: Function;

  @campaignsModule.State('campaigns') campaigns!: Array<CampaignModel>;

  @quicktippsModule.Mutation('showQuicktippsDialog') showQuicktippsDialog!: Function;
  @quicktippsModule.Action('deleteOnCampaign') deleteQuicktippsCampaign!: Function;

  @infoModule.State('loading') loading!: boolean;

  // Typescript $refs declaration
  $refs!: {
    campaignForm: HTMLFormElement;
  };

  loadActive = false;
  validCampaignForm = false;

  fileCampaign: any | null | undefined = null;
  fileCampaignContent: any | null | undefined = null;

  datetimeStart: Date | null = null;
  datetimeEnd: Date | null = null;


  selectedDeeplinkTypeValue = '';
  deeplinkTypeQuicktipp = false;

  // Special & Discount
  description1: any = '';
  description2: any = '';
  description3: any = '';
  headline = '';

  // Special
  cent_price: any = '';

  // new
  fileBarcodePrint: any | null | undefined = null;
  discountBarcodeDescription: any = '';
  fileDiscountCode: any | null | undefined = null;

  //misc
  dimenIdCampaign = DIMENS.CAMPAIGN.identifier
  hasCampaignDimen = false;

  // references for In-UI checks
  typeCampaign = CampaignType.Campaign
  typeSpecial = CampaignType.Special
  typeDiscount = CampaignType.Discount

  acceptImage = AcceptFlag.ACCEPT_IMAGERY
  acceptPdf = AcceptFlag.ACCEPT_PDF

  //Validation für die drei Beschreibungsfelder
  descriptionRules = {
    required: (v: string) => !!v || 'Bitte fügen Sie eine Beschreibung hinzu',
    length: (v: any) => v?.length <= 50 || 'Maximale Zeichenanzahl überschritten!',
    lengthName: (v: any) => v?.length <= 35 || 'Maximale Zeichenanzahl überschritten!',
  };
  quicktippDeeplinkValue = DEEPLINK.QUICKTIPP.serverIdentifier

  get dialogTitle() {
    let title = ''
    switch (this.state.type) {
      case CampaignType.Special:
        title += 'Sonderprodukt'
        break;
      case CampaignType.Discount:
        title += 'Rabattangebot'
        break;
      default:
        title += 'Standard-Kampagne'
    }
    title += ' '
    if (this.state.isNew) {
      title += 'anlegen'
    } else {
      title += 'bearbeiten'
    }
    return title
  }

  get inputText() {
    let resultText = ''
    switch (this.state.type) {
      case CampaignType.Special:
        resultText += 'Sonderprodukt-Kampagne'
        break;
      case CampaignType.Discount:
        resultText += 'für Rabattangebot-Kampagne'
        break;
      default:
        resultText += 'Standard-Kampagne'
    }
    return resultText
  }

  get deeplinkTypes() {
    switch (this.state.type) {
      case CampaignType.Campaign:
        return [
          {name: DEEPLINK.NONE.name, value: DEEPLINK.NONE.serverIdentifier},
          {name: DEEPLINK.QUICKTIPP.name, value: DEEPLINK.QUICKTIPP.serverIdentifier}
        ];
      default:
        return []
    }
  }

  get conditionalProps() {

    if (this.state.type === this.typeCampaign) {
      return {fluid: false}
    } else {
      return {fluid: true}
    }
  }

  get isDiscount() {
    return (this.state.type === this.typeDiscount)
  }

  get isSpecial() {
    return (this.state.type === this.typeSpecial)
  }

  get inputClasses() {
    return {
      defaultCampaign: (this.state.type === this.typeCampaign),
    };
  }

  get CampaignSrc() {
    return this.CampaignImages ? this.getImageIfExisting(DIMENS.CAMPAIGN.identifier)?.url ?? '' : ''
  }

  get specialSrc() {
    return this.CampaignProp?.category_content?.printables?.content ?? ''
  }

  get discountSrc() {
    return this.CampaignProp?.category_content?.printables?.discount_content ?? ''
  }

  get discountSpielscheinSrc() {
    return this.CampaignProp?.category_content?.printables?.content ?? ''
  }

  get CampaignImages() {
    return this.CampaignProp ? this.CampaignProp.images : null
  }

  get CampaignProp() {
    return this.state ? this.state.campaign : null
  }

  get QuicktippsProp() {
    return this.state.quicktipps
  }

  get showSuperadminUI(): boolean {
    return ((this.user.role.role_id === ROLES.SUPERADMIN.role_id) && this.hasCampaignDimen)
  }

  get labelDescriptionRow3(): string {
    if (this.isDiscount) {
      return "Beschreibung - Zeile 3: Preis"
    } else {
      return "Beschreibung - Zeile 3"
    }
  }

  //Validation für den Namen des Spielauftrags
  headlineRules = (v: string) => !!v || 'Bitte geben Sie einen Namen für den Spielauftrag ein'

  //Validation für das Preisfeld
  priceRules = (v: string) => !!v || 'Bitte geben Sie den Preis in Cents an'

  //Validation für das QR-Code Wertfeld
  discountRules = (v: string) => !!v || 'Bitte fügen Sie eine Beschreibung hinzu'

  openImage(url: string) {
    window.open(url)
  }

  getDimenIfExisting(identifier: string): ImageDimension | undefined {
    return this.dimens?.find(e => e.name === identifier)
  }

  getImageIfExisting(identifier: string): CampaignImage | undefined {
    return this.CampaignProp?.images?.find(e => e.type === identifier)
  }

  onCancel() {
    this.clearData();
    this.fetchCampaigns()
  }

  clearData() {
    // Delay a bit so we can properly utilize helper flags
    setTimeout(() => {
      // Important to first reset
      if (this.$refs.campaignForm) {
        this.$refs.campaignForm.reset();
      }
      this.fileCampaign = null;
      this.datetimeStart = null;
      this.datetimeEnd = null;
      this.selectedDeeplinkTypeValue = '';
      this.deeplinkTypeQuicktipp = false;

      this.fileCampaignContent = null;
      this.description1 = '';
      this.description2 = '';
      this.description3 = '';
      this.headline = '';
      this.cent_price = '';
      this.fileBarcodePrint = null;
      this.discountBarcodeDescription = '';
      this.fileDiscountCode = null;
    }, 100)
  }

  @Watch('state.visible')
  onShowChanged() {
    if (this.state.visible) {
      this.fileCampaign = null;

      if (this.state.type === this.typeCampaign) {
        this.hasCampaignDimen = this.getDimenIfExisting(this.dimenIdCampaign) != undefined;
      }

      if (this.state.isNew) {
        this.clearData();
      } else {
        this.fetchQuicktipps({campaignId: this.CampaignProp?.id}).then(() => {
          if (!this.CampaignProp?.quicktipps || this.CampaignProp?.quicktipps?.length === 0) {
            this.selectedDeeplinkTypeValue = DEEPLINK.NONE.serverIdentifier
          } else if (this.CampaignProp?.quicktipps?.length > 0) {
            this.selectedDeeplinkTypeValue = DEEPLINK.QUICKTIPP.serverIdentifier
          }
        });
        this.datetimeStart = this.CampaignProp?.start ? timeUtil.parseToDate(String(this.CampaignProp?.start)) : null;
        this.datetimeEnd = this.CampaignProp?.end ? timeUtil.parseToDate(String(this.CampaignProp?.end)) : null;

        if (this.state.type == this.typeDiscount || this.state.type == this.typeSpecial) {

          var descriptionHolder = this.CampaignProp?.category_content?.printables?.description ?? ['', '', ''];

          this.headline = this.CampaignProp?.category_content?.printables?.title ?? '';
          this.description1 = descriptionHolder[0] ?? '';
          this.description2 = descriptionHolder[1] ?? '';
          this.description3 = descriptionHolder[2] ?? '';

          if (this.state.type == this.typeDiscount) {
            this.discountBarcodeDescription = this.CampaignProp?.category_content?.printables?.discount_description ?? '';
          }
          if (this.state.type == this.typeSpecial) {
            this.cent_price = this.CampaignProp?.category_content?.printables?.price ?? '';
          }
        }


      }
    } else {
      //Reset variables when dialog dismisses
      this.clearData();
    }
  }

  @Watch('loading')
  onLoadingChanged(val: boolean) {
    this.loadActive = val
  }

  async onConfirm() {
    const localErrorHandler = (message: string) => {
      this.showMessage({type: 'info/error', msg: message});
    }
    let criteria = false;

    if (this.state.type == this.typeDiscount || this.state.type == this.typeSpecial) {
      criteria = this.$refs.campaignForm.validate();
    } else {
      criteria = this.$refs.campaignForm.validate() && this.datetimeStart != null && this.datetimeEnd != null;
    }
    if (criteria) {
      const formData = new FormData();

      //Single selection so this is safe
      if (this.fileCampaign && typeof this.fileCampaign === "object" && 'name' in this.fileCampaign) {
        formData.append("name", this.fileCampaign.name);
        formData.append("image", this.fileCampaign);
        formData.append("type", DIMENS.CAMPAIGN.identifier);
      }

      if (this.state.isNew) {
        // We could take them from store but request them anew on purpose (case: cms duplicated tabs in browser)
        this.getAllCampaigns().then((campaignModels: Array<CampaignModel>) => {
          if (campaignModels.length >= VALUES.CAMPAIGNS_MAX_COUNT) {
            localErrorHandler('Die maximale Kampagnenanzahl wurde erreicht.')
          } else {
            this.doDimensMatchWithFileProvided(this.fileCampaign, DIMENS.CAMPAIGN).then(() => {
              this.commenceCampaignCreation(formData)
            }).catch(() => {
              localErrorHandler('Bitte ein Bild mit angegebener Abmessung hochladen')
            })
          }
        }).catch((err: any) => {
          console.log('err', err)
        })
      } else {

        let data: any = {
          id: this.CampaignProp?.id,
          start: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
          end: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
          // category stays the same
        };

        if (this.fileCampaignContent) {
          data.descriptive_content_base64 = await toBase64(this.fileCampaignContent).catch(null);
        }

        if (this.state.type == this.typeDiscount || this.state.type == this.typeSpecial) {
          let printables: any = {
            title: this.headline,
            description: [
              this.description1,
              this.description2,
              this.description3,
            ],
            discount_description: '',
          }

          if (this.fileBarcodePrint !== null) {
            const basenull = await toBase64(this.fileBarcodePrint).catch(null);

            if (basenull !== null) {
              printables.content_base64 = basenull;
            }
          }

          if (this.state.type == this.typeDiscount) {
            if (this.fileDiscountCode !== null) {
              const discountnull = await toBase64(this.fileDiscountCode).catch(null);

              if (discountnull !== null) {
                printables.discount_content_base64 = discountnull;
              }
            }
            printables.discount_description = this.discountBarcodeDescription;
            printables.price = '';
          }

          if (this.state.type == this.typeSpecial) {
            printables.price = this.cent_price;
            // Preis nur für Sonderangebot
          }

          if (this.fileCampaignContent) {
            data.descriptive_content_base64 = await toBase64(this.fileCampaignContent).catch(null);
          }

          data.category_content = {
            printables: printables
          }
        }

        // if deeplink NONE is active or no deeplink selected send quicktipp: null explicitly
        // this will handle deletion on server
        if (this.selectedDeeplinkTypeValue.length == 0 || this.selectedDeeplinkTypeValue === DEEPLINK.NONE.serverIdentifier) {
          data.quicktipps = null;
        }

        // special loading indicator handling
        this.setLoadingDismissalFlag(true);

        const doesUpdateImages = (this.fileCampaign != null);

        // Currently hardcoded for this project but could be subject to change
        // if new dimens are defined
        const identifierOfImageToUpdate = DIMENS.CAMPAIGN.identifier;

        if (doesUpdateImages) {
          if (formData && identifierOfImageToUpdate) {
            this.handleUploadResponse(this.CampaignProp!, formData, identifierOfImageToUpdate).then(() => {
              this.finishCampaignUpdate(data)
            })
          }
        } else {
          this.finishCampaignUpdate(data)
        }
      }
    } else {
      localErrorHandler('Eingaben sind nicht vollständig oder fehlerhaft.');
    }
  }

  async commenceCampaignCreation(formDataPhone: FormData) {
    let data: any = {
      start: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
      end: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
      category: this.state.type.serverId,
      prio: 300, // irrelevant
    };

    if (this.fileCampaignContent) {
      const base64 = await toBase64(this.fileCampaignContent).catch(null)
      if (base64) {
        data.descriptive_content_base64 = base64
      }
    }

    if (this.state.type == this.typeDiscount || this.state.type == this.typeSpecial) {
      let printables: any = {
        title: this.headline,
        description: [
          this.description1,
          this.description2,
          this.description3,
        ],
        discount_description: '',
      }

      const basenull = await toBase64(this.fileBarcodePrint).catch(null);

      if (basenull !== null) {
        printables.content_base64 = basenull;
      }

      if (this.state.type == this.typeDiscount) {
        // printables.discount_content = this.fileDiscountCode;
        const discountnull = await toBase64(this.fileDiscountCode).catch(null);

        if (discountnull !== null) {
          printables.discount_content_base64 = discountnull;
        }
        printables.discount_description = this.discountBarcodeDescription;
        // Laut Doc required
        printables.price = '';
      }

      if (this.state.type == this.typeSpecial) {
        printables.price = this.cent_price;
        // Preis nur für Sonderangebot
      }

      if (this.fileCampaignContent) {
        data.descriptive_content_base64 = await toBase64(this.fileCampaignContent).catch(null);
      }

      data.category_content = {
        printables: printables
      }
    }

    // special loading indicator handling
    this.setLoadingDismissalFlag();

    this.createCampaign(data).then((campRes: CampaignModel) => {
      this.adjustDialog({campaign: campRes, isNew: false});

      this.handleUploadResponse(campRes, formDataPhone, DIMENS.CAMPAIGN.identifier).then(() => {
        this.finishCampaignDialog()
      })
    })
  }

  finishCampaignDialog() {
    this.fetchCampaigns();
    this.showMessage({type: 'info/success', msg: 'Kampagne erfolgreich angelegt'});
  }

  finishCampaignUpdate(data: any) {
    this.setLoadingDismissalFlag(false);
    this.updateCampaign(data).then(() => {
      this.showMessage({type: 'info/success', msg: 'Kampagne erfolgreich editiert'});
    })
  }

  handleUploadResponse(campRes: CampaignModel, formData: any, oldImageIdentifierToDelete: string) {
    return new Promise((resolve) => {
      const campaignImages = this.CampaignImages
      this.uploadImage({campaign_id: campRes.id, image: formData}).then(async () => {
        if (campaignImages) {
          await this.deleteCampaignImages(campRes.id ?? -1, campaignImages, oldImageIdentifierToDelete)
        }
        // The request is happening so fast, that the fetch campaigns request is still getting deleted images
        // let's add some delay here
        setTimeout(() => resolve(true), 1000)
      })
    })
  }

  deleteCampaignImages(campaign_id: number, campaignImages: Array<CampaignImage>, imageTypeToDelete: string) {
    return new Promise((resolve) => {
      campaignImages.forEach((image: CampaignImage) => {
        if (image.type === imageTypeToDelete) {
          const data = {
            campaign_id: campaign_id,
            'image_id': image.id
          };
          this.deleteImage(data)
        }
      });
      resolve(true)
    })
  }

  editQuicktipp(quicktipp: QuicktippModel) {
    this.showQuicktippsDialog({
      quicktipp: quicktipp,
      isNew: false,
      context: QuicktippType.CAMPAIGN.identifier,
      campaignId: this.CampaignProp ? this.CampaignProp.id : null
    })
  }

  deleteQuicktipp(quicktipp: QuicktippModel) {
    QuestionStore.poseQuestion(
        null,
        `Diese Quicktipps wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex: number) => {
      if (answerIndex === 0) {
        this.deleteQuicktippsCampaign({
          campaignId: this.CampaignProp ? this.CampaignProp.id : null,
          quicktippId: quicktipp.id
        })
      }
    })
  }

  canCreateQuicktipps(): boolean {
    return ((this.QuicktippsProp as QuicktippModel[])?.length ?? 0) < 2;
  }

  showQTDialog() {
    if (this.CampaignProp) {
      this.showQuicktippsDialog({
        quicktipps: null,
        isNew: true,
        context: QuicktippType.CAMPAIGN.identifier,
        campaignId: this.CampaignProp ? this.CampaignProp.id : null
      })
    } else {
      this.showInformation({msg: 'Bitte speichern Sie die Kampagne-Daten, bevor Sie mit den Quicktipps fortfahren.'});
    }
  }

  datePickerLabel(isEndtime: boolean): string {
    if (isEndtime) {
      return "Endzeit"
    } else {
      return "Startzeit"
    }
  }

  deeplinkLabel(): string {
    return "Deeplink"
  }

  // noinspection JSUnusedGlobalSymbols
  doesCurrentDataOverlapWithActiveCampaigns(): boolean {
    const currentStart = moment(this.datetimeStart).valueOf();
    const currentEnd = moment(this.datetimeEnd).valueOf();

    const futureCampaignsWithCurrentExcluded = this.campaigns.filter((campaign: CampaignModel) =>
        //current obviously only exists when editing (!isNew)
        (moment(String(campaign.start)).isAfter() || moment(String(campaign.end)).isAfter()) && (campaign.id != this.CampaignProp?.id)
    )

    for (const futureCampaign of futureCampaignsWithCurrentExcluded) {
      const activeStart = moment(futureCampaign.start).valueOf()
      const activeEnd = moment(futureCampaign.end).valueOf()

      const startDoesIntersect = timeUtil.between(currentStart, activeStart, activeEnd);
      const endDoesIntersect = timeUtil.between(currentEnd, activeStart, activeEnd);

      if (startDoesIntersect || endDoesIntersect) {
        return true
      }
    }
    return false
  }

  doDimensMatchWithFileProvided(file: any, dimenToFollow: Dimension): Promise<any> {
    return new Promise((resolve, reject) => {
      const dimens = this.dimens?.find(e => e.name === dimenToFollow.identifier)
      if (dimens) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = (rs: any) => {
            const height = rs.currentTarget['height'];
            const width = rs.currentTarget['width'];
            if (height != dimens.height || width != dimens.width) {
              reject()
            } else {
              resolve(true)
            }
          };
        };
      } else {
        resolve(true)
      }
    })
  }

  requestNewAdImageUrl(identifier: string): string {
    const dimen = this.getDimenIfExisting(identifier)
    if (dimen) {
      return `https://picsum.photos/${dimen.width}/${dimen.height}`
    }
    return ''
  }

  superAdminRequestImageText(): string {
    return 'Kampagnenbild anfordern'
  }
}
