export const PRODUCTS = {
    SVA: {name: 'service'}
};

export interface Product {
    name: string;
}

export function productForIdentifier(name: string): Product | null {
    return PRODUCTS.SVA;
}
