
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import vueDropzone from 'vue2-dropzone'
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import moment from 'moment'
import {DocumentCategory, DocumentDialogModel} from "@/stores/documents/types";
import timeUtil from "@/utils/time"
import {Organization} from "@/enum/Organizations";
import {VALUES} from "@/constants/values";
import AppFileInput from "@/components/AppFileInput.vue";
import {toBase64} from "@/utils/misc";
import {AcceptFlag} from "@/enum/AcceptFlag";

const authModule = namespace('auth');
const documentsModule = namespace('documents');
const infoModule = namespace('info');
const sortOrder = ['T1', 'T2', 'T3', 'T4', 'T13', 'T12', 'T7', 'T8', 'T5', 'T6', 'T10', 'T9', 'T11']; // Sort order for document types

@Component({
  name: 'DocumentDialog',
  components: {
    AppFileInput,
    vueDropzone,
    VDatetimePicker
  }
})
export default class DocumentDialog extends Vue {
  @authModule.Getter('gesellschaft') organization!: Organization;

  @documentsModule.State('docDialogState') state!: DocumentDialogModel;
  @documentsModule.Mutation('dismissDocumentDialog') cancelDialog!: Function;
  @documentsModule.Action('upload') uploadDoc!: Function;
  @documentsModule.Action('create') saveDoc!: Function;
  @documentsModule.Action('update') updateDoc!: Function;

  @documentsModule.Action('createDynamic') saveDynamicDoc!: Function;
  @documentsModule.Action('updateDynamic') updateDynamicDoc!: Function;

  @infoModule.State('loading') loading!: boolean;

  loadActive = false;

  uploadData: any | null = null;
  category: DocumentCategory | null = null;
  title = '';
  key = '';

  datetimeStart: Date | null = null;
  datetimeEnd: Date | null = null;

  get accept(): string {
    // S5 (Fragebogen Spielerschutz) is only possible image in DocumentDialog
    return this.key == 'S5' ? AcceptFlag.ACCEPT_IMAGE_AND_PDF : AcceptFlag.ACCEPT_PDF
  }

  get categories() {
    if (this.organization.documents?.availableCategories) {
      return this.organization.documents.availableCategories
    }
    return []
  }

  get dialogTitle() {
    if (!this.state.isNew && this.state.visible) {
      return 'Dokument bearbeiten'
    } else if (this.state.isNew && this.state.visible) {
      return 'Neues Dokument'
    } else {
      return ''
    }
  }

  get keys() {
    if (this.category && this.organization.documents?.availableCategories) {
      let validTypes = this.organization.documents?.availableCategories.find(category => category === this.category)?.validTypes || [];

      // Sort the validTypes according to the sortOrder
      validTypes = validTypes.sort((a, b) => {
        const indexA = sortOrder.indexOf(a.key ?? '');
        const indexB = sortOrder.indexOf(b.key ?? '');

        if (indexA === -1) return 1; // Unknown items at the end
        if (indexB === -1) return -1; // Unknown items at the end

        return indexA - indexB; // Sort by index in sortOrder
      });

      return validTypes;
    } else {
      return [];
    }
  }

  get disabled() {
    return !this.state.isNew;
  }

  get isDynamicDocumentCategory(): boolean {
    return ((this.category?.serverValue === 'Sonderauslosung' || this.category?.serverValue == VALUES.DYNAMIC_DOCUMENT_CATEGORY_LOT_RUNTIMES) &&
        this.category.validTypes.length === 0)
  }

  onCancel() {
    //this.$refs.myVueDropzone.removeAllFiles()
    this.cancelDialog();
    this.uploadData = null;
    this.category = null;
    this.title = '';
    this.key = '';
    this.datetimeStart = null
    this.datetimeEnd = null
  }

  onConfirm() {
    if (this.isDynamicDocumentCategory) {
      this.handleDynamicDocuments()
    } else {
      this.handleStandartDocuments()
    }
  }

  handleStandartDocuments() {
    if (this.state.isNew) {
      const formData = new FormData();
      //Single selection so this is safe
      if (this.uploadData) {
        formData.append("document", this.uploadData);
      }
      this.uploadDoc(formData).then((res: any) => {
        const data = {
          'key': this.key,
          'filename': res.filename,
          'title': this.title,
          'category': this.category?.serverValue,
          'publish_at': this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
          'publish_until': this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null
        };
        this.saveDoc(data).then(() => {
          this.onCancel()
        });
      }).catch((err: any) => {
        //
      })
    } else if (!this.state.isNew) {
      const url = this.state.document?.src;
      const urlStripped = url?.substr(url.indexOf('file/') + 5, url?.length);

      if (this.datetimeStart) {
        this.datetimeStart = moment(String(this.datetimeStart)).toDate()
      }

      if (this.datetimeEnd) {
        this.datetimeEnd = moment(String(this.datetimeEnd)).toDate()
      }

      const data = {
        'filename': urlStripped,
        'publish_at': this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
        'publish_until': this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null
      };
      this.updateDoc(data).then(() => {
        this.onCancel()
      });
    }
  }

  async handleDynamicDocuments() {
    if (this.state.isNew) {
      const parsedData = await toBase64(this.uploadData).catch(null)
      this.saveDynamicDoc({
        publish_at: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
        publish_until: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
        document_base64: parsedData,
        title: this.title,
        category: this.state.preDefinedCategoryServerId ?? this.category?.serverValue,
        comment: null,
        user_filename: this.uploadData.name // real document name
      }).then(() => {
        this.onCancel()
      });
    } else if (!this.state.isNew) {
      this.updateDynamicDoc({
        id: this.state.document?.document_id,
        model: {
          title: this.title,
          publish_at: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
          publish_until: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
        }
      }).then(() => {
        this.onCancel()
      });
    }
  }

  @Watch('state.visible')
  onShowDialog() {
    if (this.state.preDefinedCategoryServerId) {
      let preselectedCategory = this.organization.documents?.availableCategories.find((categoryObject: any) =>
          categoryObject.serverValue === this.state.preDefinedCategoryServerId
      )
      if (preselectedCategory) {
        this.category = preselectedCategory
      }
    }

    if (!this.state.isNew) {
      if (this.state.document?.publish_at) {
        this.datetimeStart = new Date(this.state.document?.publish_at);
      } else {
        this.datetimeStart = null
      }

      if (this.state.document?.publish_until) {
        this.datetimeEnd = new Date(this.state.document?.publish_until);
      } else {
        this.datetimeEnd = null
      }

      if (this.state.document?.category) {
        let preselectedCategory = this.organization.documents?.availableCategories.find((categoryObject: any) =>
            categoryObject.serverValue === this.state.document?.category
        )
        if (preselectedCategory) {
          this.category = preselectedCategory
        }
      }
      if (this.state.document?.key) {
        this.key = this.state.document?.key
      }
      // title is set by Watcher on key - onKeyChange
    }
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  @Watch('category')
  onCategoryChange(val: DocumentCategory) {
    this.category = val
  }

  @Watch('key')
  onKeyChange(documentKey: string) {
    if (this.category && this.organization.documents?.availableCategories) {
      // could do this in other ways, maybe improve in future or change when necessary
      // document title is applied on dynamic documents
      this.title = this.findTitle(documentKey) ?? this.state?.document?.title ?? ''
    }
  }

  findTitle(documentKey: string): string | null {
    if (this.organization.documents?.availableCategories) {
      for (const category of this.organization.documents?.availableCategories) {
        for (const document of category.validTypes) {
          if (document.key === documentKey) {
            return document.title
          }
        }
      }
    }
    return null
  }

  isDefinedCategory(): boolean {
    return this.organization.documents?.availableCategories !== undefined
  }
}
