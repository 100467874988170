export const VALUES = {
    MAX_TABS_IN_NAVIGATION: 5,
    PERSISTED_AUTH: 'auth',
    LOADING_DELAY: 500,
    SNACKBAR_TIMEOUT: 2500,
    DATE_TIME_FORMAT: 'DD.MM.YYYY HH:mm',
    DATE_FORMAT: 'DD.MM.YYYY',
    TIME_FORMAT: 'HH:mm',
    CAMPAIGNS_MAX_COUNT: 20,
    QUICKTIPPS_PER_LOTTERY_MAX_COUNT: 3,
    LOCALE: 'de-DE',
    SCREENSAVER_DOCUMENT_KEY: 'screensaver',
    DYNAMIC_DOCUMENT_CATEGORY_LOT_RUNTIMES: 'lot',
    DYNAMIC_DOCUMENT_PREFIX_LOT_RUNTIMES: 'LO',
    DYNAMIC_DOCUMENT_PREFIX_TB_SPECIAL: 'SO',
    DYNAMIC_LOT_RUNTIMES_MAX_COUNT: 4,
};
