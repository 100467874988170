import moment from 'moment'
import {VALUES} from "@/constants/values";

export default {

    formattedDate(dateString: string | null): string {
        if (!dateString) {
            return ''
        }
        return moment(String(dateString)).format(VALUES.DATE_TIME_FORMAT)
    },

    parseToDate(dateString: string | null): Date | null {
        if (!dateString) {
            return null
        }
        return moment(String(dateString)).toDate()
    },

    parseToMillis(dateString: string | null): number {
        if (!dateString) {
            return 0
        }
        return moment(String(dateString)).valueOf()
    },

    objectIsActive(startDateString: string | null, endDateString: string | null): boolean {
        if ((startDateString != null && startDateString.length > 0) && (endDateString != null && endDateString.length > 0)) {
            //If Object serves both dateStrings check if the moment is inbetween now
            return moment().isBetween(moment(String(startDateString)), moment(String(endDateString)))
        } else if (startDateString != null && startDateString.length > 0) {
            //If Object serves only start dateString check if moment is before now
            return moment(String(startDateString)).isBefore()
        } else if (endDateString != null && endDateString.length > 0) {
            //If Object serves only end dateString check if moment is after now
            return moment(String(endDateString)).isAfter()
        } else {
            //If Object serves no dateString it is not constraint and always active
            return true
        }
    },

    between(x: number, min: number, max: number): boolean {
        return x >= min && x <= max;
    }
}
