import {GAMETYPES} from "@/enum/GameTypes";

export const DRAWDAYS = {
    MONDAY: {displayTitle: "Mo", identifier: "1", rel: []},
    TUESDAY: {
        displayTitle: "Di",
        identifier: "2",
        rel: [GAMETYPES.EUROJACKPOT.displayTitle, GAMETYPES.EUROJACKPOT_SYSTEM.displayTitle]
    },
    WEDNESDAY: {
        displayTitle: "Mi",
        identifier: "3",
        rel: [GAMETYPES.LOTTO.displayTitle, GAMETYPES.LOTTO_SYSTEM.displayTitle]
    },
    THURSDAY: {displayTitle: "Do", identifier: "4", rel: []},
    FRIDAY: {
        displayTitle: "Fr",
        identifier: "5",
        rel: [GAMETYPES.EUROJACKPOT.displayTitle, GAMETYPES.EUROJACKPOT_SYSTEM.displayTitle]
    },
    SATURDAY: {
        displayTitle: "Sa",
        identifier: "6",
        rel: [GAMETYPES.LOTTO.displayTitle, GAMETYPES.LOTTO_SYSTEM.displayTitle]
    },
    SUNDAY: {displayTitle: "So", identifier: "7", rel: []},
    WEDNESDAY_a_SATURDAY: {
        displayTitle: "Mi & Sa",
        identifier: "3,6",
        rel: [GAMETYPES.LOTTO.displayTitle, GAMETYPES.LOTTO_SYSTEM.displayTitle]
    },
    TUESDAY_a_FRIDAY: {
        displayTitle: "Di & Fr",
        identifier: "2,5",
        rel: [GAMETYPES.EUROJACKPOT.displayTitle, GAMETYPES.EUROJACKPOT_SYSTEM.displayTitle]
    },
    DAY_OF_NEXT_DRAW: {
        displayTitle: "Nächste Ziehung",
        identifier: "next",
        rel: [GAMETYPES.LOTTO.displayTitle, GAMETYPES.LOTTO_SYSTEM.displayTitle, GAMETYPES.EUROJACKPOT.displayTitle]
    }
};
