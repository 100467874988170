
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {DocumentModel, isDynamicDoc} from "@/stores/documents/types";
import timeUtil from "@/utils/time"
import Draggable from "vuedraggable";
import AppHyperlink from "@/components/AppHyperlink.vue";

const documentsModule = namespace('documents');

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return timeUtil.formattedDate(value)
  }
});

@Component({
  name: 'doc-list-row',
  components: {
    AppHyperlink,
    Draggable,
  }
})

export default class DocListRow extends Vue {
  @documentsModule.Mutation('showDocumentDialog') showDialog!: Function;
  @documentsModule.Action('delete') deleteDocument!: Function;
  @documentsModule.Action('deleteDynamic') deleteDynamicDocument!: Function;

  @Prop({type: Array, required: true, default: []}) readonly docs!: DocumentModel[];
  @Prop({type: Boolean, required: false, default: false}) readonly isDraggable!: boolean;

  textClass(doc: DocumentModel): string {
    return timeUtil.objectIsActive(doc.publish_at, doc.publish_until) ? 'black--text' : 'grey--text'
  }

  iconResource(): string {
    if (this.isDraggable) {
      return 'mdi-drag-horizontal-variant'
    } else {
      return 'mdi-file-document-outline'
    }
  }

  clicked(doc: DocumentModel) {
    if (doc.src) {
      window.open(doc.src)
    }
  }

  onDropCallback(event: CustomEvent) {
    this.$emit('drop', this.docs.map((doc: DocumentModel) => doc.document_id))
  }

  onEditDocument(doc: DocumentModel) {
    this.showDialog({document: doc, isNew: false})
  }

  onDeleteDocument(doc: DocumentModel) {
    QuestionStore.poseQuestion(
        null,
        `Dokument ${doc.title} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        if (isDynamicDoc(doc)) {
          this.deleteDynamicDocument(doc.document_id)
        } else {
          this.deleteDocument({documentId: doc.document_id})
        }
      }
    })
  }
}
