import {GAMETYPES} from "@/enum/GameTypes";

export const EXTRAGAMES = {
    SUPER6: {displayTitle: GAMETYPES.SUPER6.displayTitle, value: [GAMETYPES.SUPER6.displayTitle]},
    SPIEL77: {displayTitle: GAMETYPES.SPIEL77.displayTitle, value: [GAMETYPES.SPIEL77.displayTitle]},
    SIEGERCHANCE: {displayTitle: GAMETYPES.SIEGERCHANCE.displayTitle, value: [GAMETYPES.SIEGERCHANCE.displayTitle]},
    PLUS5: {displayTitle: GAMETYPES.PLUS5.displayTitle, value: [GAMETYPES.PLUS5.displayTitle]},
    S6_SP77: {
        displayTitle: `${GAMETYPES.SUPER6.displayTitle} & ${GAMETYPES.SPIEL77.displayTitle}`,
        value: [GAMETYPES.SUPER6.displayTitle, GAMETYPES.SPIEL77.displayTitle]
    },
    S6_SP77_SC: {
        displayTitle: `${GAMETYPES.SUPER6.displayTitle} & ${GAMETYPES.SPIEL77.displayTitle} & ${GAMETYPES.SIEGERCHANCE.displayTitle}`,
        value: [GAMETYPES.SUPER6.displayTitle, GAMETYPES.SPIEL77.displayTitle, GAMETYPES.SIEGERCHANCE.displayTitle]
    },
};
