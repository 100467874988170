
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: 'AppHyperlink'
})
export default class AppHyperlink extends Vue {
  @Prop({type: String, required: true, default: ''}) readonly label!: string;
  @Prop({type: String, default: ''}) readonly url!: string;
  @Prop({type: String, default: ''}) readonly iconRes!: string;
  @Prop({type: Boolean, default: true}) readonly underline!: boolean;
  // should be a resolvable framework color (e.g primary, teal, info etc.)
  // if using modifier (e.g --darken-2 color is discarded)
  @Prop({type: String, default: 'primary'}) readonly color!: string;

  textClass() {
    let result = 'mx-0 px-0'
    result += ` ${this.color}--text`
    if (this.underline) {
      result += ' text-decoration-underline'
    }
    if (this.iconRes.trim().length > 0) {
      result += ' text-truncate'

    }
    return result
  }

  open() {
    if (this.url && this.url !== '') {
      window.open(this.url)
    }
  }
}
