
import Vue from "vue";
import {Component} from "vue-property-decorator";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {ReportDialogModel, ReportModel} from "@/stores/reports/types";
import timeUtil from "@/utils/time"

const reportsModule = namespace('reports');

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return timeUtil.formattedDate(value)
  }
});

@Component({
  name: 'report-list-row',
  props: ['report']
})

export default class DocListRow extends Vue {
  @reportsModule.Action('getSingular') requestSingleReport!: Function;
  @reportsModule.Action('delete') deleteErrorLog!: Function;
  @reportsModule.State('dialogState') state!: ReportDialogModel;
  @reportsModule.Mutation('showReportDialog') showDialog!: Function;

  clicked(report: ReportModel) {
    this.requestSingleReport({report_id: String(report.report_id)}).then((res: ReportModel) => {
      this.showDialog({report: res})
    }).catch((err: any) => {
      //
    })
  }

  onDeleteReport(report: ReportModel) {
    QuestionStore.poseQuestion(
        null,
        `Bericht wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        this.deleteErrorLog({reportId: String(report.report_id)})
      }
    })
  }
}
