
import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {CampaignDialogModel} from "@/stores/campaigns/types";
import {convertGameTypeFromString, GAMETYPES} from "@/enum/GameTypes";
import {DRAWDAYS} from "@/enum/DrawDays";
import {EXTRAGAMES} from "@/enum/ExtraGames";
import {productForIdentifier} from "@/enum/Products";
import {OrgConfig} from "@/stores/auth/types";
import {Organization} from "@/enum/Organizations";
import {QuicktippModel, QuicktippsDialogModel} from "@/stores/quicktipps/types";
import {QuicktippType} from "@/enum/QuicktippTypes";
import {VALUES} from "@/constants/values";
import {getLotterieSystemfromId, LOTTERY_SYSTEMS} from "@/enum/LotterySystems";

const authModule = namespace('auth');
const quicktippsModule = namespace('quicktipps');
const infoModule = namespace('info');

@Component({
  name: 'QuicktippsDialog',
  components: {},
})

export default class QuicktippsDialog extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @quicktippsModule.State('dialogState') state!: QuicktippsDialogModel;
  @quicktippsModule.State('quicktipps') storedQuicktipps!: Array<QuicktippModel>;
  @quicktippsModule.Mutation('dismissQuicktippsDialog') dismiss!: Function;

  @quicktippsModule.Action('create') createQuicktippSimple!: Function;
  @quicktippsModule.Action('update') updateQuicktippSimple!: Function;

  @quicktippsModule.Action('createOnCampaign') createQuicktippsCampaign!: Function;
  @quicktippsModule.Action('updateOnCampaign') updateQuicktippsCampaign!: Function;

  @infoModule.Action('error') showError!: Function;

  extraGames: string | null = null;

  quicktippsRowsNumber = 1;
  durationOrDrawingCount = 1;
  day = '';
  dialogTitle = "Quicktipp anlegen";
  canSave = true;
  quicktippType = '';
  selectedSystemId: number | null = null;

  typeGS = GAMETYPES.GLUECKSSPIRALE.displayTitle;
  typeKENO = GAMETYPES.KENO.displayTitle;

  rules = {
    notEmpty: (value: string | undefined | null) => !!value || "Dieses Feld ist erforderlich",
    minimum1: (value: string | undefined | null) => Number(value) >= 1 || "Dieses Feld ist erforderlich",
    isNumber: (value: string | undefined | null) => !!value && this.isNumber(value) || "Dieses Feld darf nur aus Ziffern bestehen",
    equalOrGreaterZero: (value: number | undefined | null) => (value !== undefined && value !== null) && value >= 0 || "Dieses Feld ist erforderlich"
  };

  get lottoSelected(): boolean {
    return this.quicktippType === GAMETYPES.LOTTO.displayTitle || this.quicktippType === GAMETYPES.LOTTO_SYSTEM.displayTitle
  }

  get lottoSystemSelected(): boolean {
    return this.quicktippType === GAMETYPES.LOTTO_SYSTEM.displayTitle
  }

  get ejpSelected(): boolean {
    return this.quicktippType === GAMETYPES.EUROJACKPOT.displayTitle
  }

  get dayOfNextDrawSelected(): boolean {
    return this.day === DRAWDAYS.DAY_OF_NEXT_DRAW.identifier
  }

  get QuicktippsTypes() {
    const types = [];
    types.push(GAMETYPES.LOTTO.displayTitle);
    types.push(GAMETYPES.LOTTO_SYSTEM.displayTitle);
    types.push(GAMETYPES.EUROJACKPOT.displayTitle);
    types.push(GAMETYPES.GLUECKSSPIRALE.displayTitle);
    types.push(GAMETYPES.KENO.displayTitle)
    return types
  }

  get QTMaxFields() {
    let max = 20;
    const product = productForIdentifier(this.config.product_id);
    const gameType = convertGameTypeFromString(this.quicktippType);

    if (product && gameType) {
      max = this.organization.siteConfig.get(product)?.fields.get(gameType) ?? 20
    }

    return max
  }

  get QTExtraGames() {
    if (this.quicktippType === GAMETYPES.KENO.displayTitle) {
      return [
        EXTRAGAMES.PLUS5,
      ]
    } else if (this.quicktippType === GAMETYPES.GLUECKSSPIRALE.displayTitle) {
      return [
        EXTRAGAMES.SUPER6,
        EXTRAGAMES.SPIEL77,
        EXTRAGAMES.SIEGERCHANCE,
        EXTRAGAMES.S6_SP77_SC
      ]
    } else {
      return [
        EXTRAGAMES.SUPER6,
        EXTRAGAMES.SPIEL77,
        EXTRAGAMES.S6_SP77
      ]
    }
  }

  get QTDrawDays() {
    return [
      DRAWDAYS.MONDAY,
      DRAWDAYS.TUESDAY,
      DRAWDAYS.WEDNESDAY,
      DRAWDAYS.THURSDAY,
      DRAWDAYS.FRIDAY,
      DRAWDAYS.SATURDAY,
      DRAWDAYS.SUNDAY,
      DRAWDAYS.WEDNESDAY_a_SATURDAY,
      DRAWDAYS.TUESDAY_a_FRIDAY,
      DRAWDAYS.DAY_OF_NEXT_DRAW
    ].filter(it => it.rel.includes(this.quicktippType))
  }

  get LottoSystems() {
    return LOTTERY_SYSTEMS.LOTTO
  }

  get QuicktippsProp() {
    return this.state.quicktipp
  }

  get durationLabel() {
    if (this.quicktippType === GAMETYPES.KENO.displayTitle) {
      return "Laufzeit in Tagen";
    } else if (this.quicktippType === GAMETYPES.GLUECKSSPIRALE.displayTitle) {
      return "Laufzeit in Wochen"
    } else {
      return "Anzahl Ziehungen"
    }
  }

  get MapBonusGamesToExtraGames() {
    const match = this.QTExtraGames.filter(
        item => item.value.sort().join(',') === this.QuicktippsProp?.bonus_games?.sort()?.join(',')
    );
    if (match.length > 0) {
      return match[0].displayTitle
    }
    return null
  }

  @Watch('quicktippType')
  onQuicktippTypeChanged(newValue: string) {
    // When editing quicktipp and switching away from LOTTO System systemId may not keep its value on server
    if (newValue !== GAMETYPES.LOTTO_SYSTEM.displayTitle) {
      this.selectedSystemId = null
    }
  }

  @Watch('state.visible')
  onShowChanged(val: CampaignDialogModel) {
    if (this.state.isNew) {
      this.dialogTitle = 'Quicktipp anlegen'
    } else {
      this.dialogTitle = 'Quicktipp bearbeiten';

      let gameId = this.QuicktippsProp?.game
      // Change regarding LBB-467; other adjustment see handleAction (around line 395)
      // This is so that adjusted quicktipps init UI of QuicktippsDialog with LOTTO values
      // won't work now for case LOTTO_SYSTEM but System cases are not selectable for Campaign Quicktipps anyway
      if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
        if (gameId == "LOTTO") {
          if (this.state.quicktipp?.system_id !== null && Number(this.state.quicktipp?.system_id) >= 0) {
            gameId = GAMETYPES.LOTTO_SYSTEM.displayTitle
          } else {
            gameId = GAMETYPES.LOTTO.displayTitle
          }
        }
      }

      this.quicktippType = gameId ?? '';
      this.extraGames = this.MapBonusGamesToExtraGames ?? '';
      this.durationOrDrawingCount = this.QuicktippsProp?.drawing_count ?? 1;
      this.day = this.QuicktippsProp?.draw_day_type ?? '';
      this.quicktippsRowsNumber = this.QuicktippsProp?.quicktipp_value ?? 1
      // safety validation to have valid system
      this.selectedSystemId = getLotterieSystemfromId(this.QuicktippsProp?.system_id ?? null)?.systemId ?? null
    }
  }

  onConfirm() {
    if (this.state.isNew) {
      if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier && this.storedQuicktipps.filter((quicktipp: QuicktippModel) => quicktipp.game === this.quicktippType).length >= VALUES.QUICKTIPPS_PER_LOTTERY_MAX_COUNT) {
        this.showError(`Die maximale Quicktippanzahl für "${this.quicktippType}" wurde erreicht`);
      } else {
        this.handleAction()
      }
    } else {
      this.handleAction()
    }
  }

  handleAction() {
    if (((this.$refs.qtForm as HTMLFormElement).validate())) {
      let gameId = this.quicktippType

      // Brandenburg only "fix" -> Creation handles mismatch in Naming of cases LOTTO and LOTTO_SYSTEM
      // campaign quicktipps doesn't; hotfix for this specific case
      if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
        switch (this.quicktippType) {
          case GAMETYPES.LOTTO.displayTitle:
          case GAMETYPES.LOTTO_SYSTEM.displayTitle:
            // replace LOTTO 6aus49 with LOTTO
            gameId = "LOTTO"
            break;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {
        quicktippData: {
          type: this.state.contextIdentifier,
          game: gameId,
          draw_day_type: this.QTDrawDays.length > 0 ? this.day : null,
          quicktipp_value: this.quicktippsRowsNumber,
          bonus_games: this.buildBonusGamesArray()
        }
      };

      // field data.quicktippData.duration is not actively send anymore by frontend
      data.quicktippData.drawing_count = this.durationOrDrawingCount;

      data.quicktippData.system_id = this.quicktippType === GAMETYPES.LOTTO_SYSTEM.displayTitle
          ? this.selectedSystemId
          : null

      if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
        data.campaignId = this.state.campaignId;
      }

      if (this.state.isNew) {
        if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
          this.createQuicktippsCampaign(data)
        } else if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier) {
          this.createQuicktippSimple(data)
        }
      } else {
        data.quicktippId = this.QuicktippsProp ? this.QuicktippsProp.id : null;
        if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
          this.updateQuicktippsCampaign(data)
        } else if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier) {
          this.updateQuicktippSimple(data)
        }
      }
    }
  }

  onCancel() {
    this.dismiss()
  }

  resetExtraGames() {
    this.extraGames = null
  }

  resetQuicktippFields() {
    this.quicktippsRowsNumber = 1
  }

  buildBonusGamesArray(): string[] | null {
    // this.extragames model holds EXTRAGAMES.<case>.displaytitle
    switch (this.extraGames) {
      case EXTRAGAMES.SUPER6.displayTitle:
        return EXTRAGAMES.SUPER6.value;
        //
      case EXTRAGAMES.SPIEL77.displayTitle:
        return EXTRAGAMES.SPIEL77.value;
        //
      case EXTRAGAMES.SIEGERCHANCE.displayTitle:
        return EXTRAGAMES.SIEGERCHANCE.value;
        //
      case EXTRAGAMES.PLUS5.displayTitle:
        return EXTRAGAMES.PLUS5.value;
        //
      case EXTRAGAMES.S6_SP77.displayTitle:
        return EXTRAGAMES.S6_SP77.value;
        //
      case EXTRAGAMES.S6_SP77_SC.displayTitle:
        return EXTRAGAMES.S6_SP77_SC.value;
      default:
        return null
    }
  }

  isNumber(value: string) {
    return !isNaN(parseFloat(value)) && !isNaN(Number(value))
  }
}
