
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {TicketModel} from "@/stores/tickets/types";
import {VALUES} from "@/constants/values";
import timeUtil from "@/utils/time";

const ticketModule = namespace('tickets');

@Component({
  name: 'ticket-list-row'
})
export default class LoseListRow extends Vue {
  @ticketModule.Mutation('showTicketDialog') showDialog!: Function;
  @ticketModule.Action('delete') deleteLose!: Function;

  @Prop({type: Array, required: true, default: []}) readonly tickets!: TicketModel[];

  get textClass() {
    return ''
  }

  centToEuro(centValue: number): string {
    return (centValue / 100).toLocaleString(VALUES.LOCALE, {style: "currency", currency: "EUR"});
  }

  formatDate(date: string): string {
    return timeUtil.formattedDate(date)
  }

  onEditTicket(lose: TicketModel) {
    this.showDialog({los: lose, isNew: false})
  }

  onDeleteTicket(ticket: TicketModel) {
    QuestionStore.poseQuestion(
        null,
        `Los ${ticket.headline} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        this.deleteLose({loseId: ticket.id})
      }
    })
  }
}
