import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {ReportModel, ReportState} from "@/stores/reports/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        reports: [],
        dialogState: {
            report: null,
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<ReportState, RootState> = {
    getAll({commit}) {
        api.get(ROUTES.REPORTS_GET_ALL)
            .then((response: AxiosResponse) => {
                commit('setReports', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    getSingular({commit}, {report_id}: { report_id: string }) {
        return new Promise((resolve, reject) => {
            api.get(ROUTES.REPORTS_GET_SINGLE.replace('{report_id}', report_id))
                .then((response: AxiosResponse) => {
                    resolve(response)
                })
                .catch((reason) => {
                    reject(reason)
                })
        })
    },
    delete({commit, dispatch}, {reportId}: { reportId: string }) {
        api.delete(ROUTES.REPORTS_DELETE.replace('{report_id}', reportId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Log erfolgreich gelöscht", {root: true});
                //reload all reports
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<ReportState> = {
    showReportDialog(state, data: { report: ReportModel | null }) {
        state.dialogState.report = data.report;
        state.dialogState.visible = true
    },
    dismissReportDialog(state) {
        state.dialogState.visible = false;
        state.dialogState.report = null;
    },
    setReports(state, reports: Array<ReportModel>) {
        state.reports = reports
    },
};

export const reports: Module<ReportState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};
