export const LOTTERY_SYSTEMS: Record<string, Array<LotterieSystem>> = {
    LOTTO: [
        {displayName: "Vollsystem 007", internalName: "VOLL_6_7", systemId: 0},
        {displayName: "Vollsystem 008", internalName: "VOLL_6_8", systemId: 1},
        {displayName: "Vollsystem 009", internalName: "VOLL_6_9", systemId: 2},
        {displayName: "Vollsystem 010", internalName: "VOLL_6_10", systemId: 3},
        {displayName: "Vollsystem 011", internalName: "VOLL_6_11", systemId: 4},
        {displayName: "Vollsystem 012", internalName: "VOLL_6_12", systemId: 5},
        {displayName: "Teilsystem 9/12", internalName: "TEIL_9_12", systemId: 10},
        {displayName: "Teilsystem 10/15", internalName: "TEIL_10_15", systemId: 11},
        {displayName: "Teilsystem 12/22", internalName: "TEIL_12_22", systemId: 16},
        {displayName: "Teilsystem 10/30", internalName: "TEIL_10_30", systemId: 12},
        {displayName: "Teilsystem 22/77", internalName: "TEIL_22_77", systemId: 27},
        {displayName: "Teilsystem 16/112", internalName: "TEIL_16_112", systemId: 22},
    ],
};

export function getLotterieSystemfromId(systemId: number | null): LotterieSystem | null | undefined {
    const result = LOTTERY_SYSTEMS.LOTTO.find((system: LotterieSystem) => system.systemId === systemId)
    return result
}

export interface LotterieSystem {
    displayName: string;
    internalName: string
    systemId: number;
}
