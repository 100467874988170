import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from "vuetify/src/locale/de";

//For future https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#questions
//-> When adding typescript - Error: Could not find a declaration file for module 'vuetify/lib’

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {de},
        current: "de",
    },
    theme: {
        themes: {
            light: {
                primary: "#D22321",     //lotto red
                secondary: "#F7DC16",   //lotto yellow
                accent: "#21d0d2",      //complementary accent to lotto red
                info: "#4DD0E1",
                success: "#81B219",
                warning: "#FFB74D",
                error: "#E57373",
                android: "#3DDC84",
                apple: "#000000",
                //
                toolbar: "#F7DC16",
            },
            dark: {
                // duplicated from light, keep them split for testing
                primary: "#D22321",     //lotto red
                secondary: "#F7DC16",   //lotto yellow
                accent: "#21d0d2",      //complementary accent to lotto red
                info: "#4DD0E1",
                success: "#81B219",
                warning: "#FFB74D",
                error: "#E57373",
                android: "#3DDC84",
                apple: "#f6f3ed",
                //
                toolbar: "#9a9a9b",
            }
        },
    },
    icons: {
        iconfont: "mdi"
    },
});

