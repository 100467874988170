import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {ROUTES} from "@/constants/routes";
import {QuicktippModel, QuicktippsState} from "@/stores/quicktipps/types";

const getDefaultState = () => {
    return {
        quicktipps: [],
        dialogState: {
            isNew: false,
            quicktipp: null,
            visible: false,
            contextIdentifier: "",
            campaignId: null
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<QuicktippsState, RootState> = {

    /**
     * =======================================================
     * ================ STANDALONE QUICKTIPPS ================
     * =======================================================
     */

    getAll({commit}) {
        api.get(ROUTES.QUICKTIPPS_GET_ALL).then(response => {
            commit('setQuicktipps', response)
        }).catch((reason) => {
            if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                commit('info/error', reason.description, {root: true})
            } else {
                commit('info/error', reason, {root: true})
            }
        })
    },

    create({commit, dispatch}, data: { quicktippData: QuicktippModel }) {
        api.post(ROUTES.QUICKTIPPS_CREATE_NEW, data.quicktippData)
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich angelegt", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    update({commit, dispatch}, data: { quicktippId: number; quicktippData: QuicktippModel }) {
        api.put(ROUTES.QUICKTIPPS_UPDATE
            .replace('{quicktipp_id}', String(data.quicktippId)), data.quicktippData)
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich bearbeitet", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    delete({commit, dispatch}, data: { quicktippId: number }) {
        api.delete(ROUTES.QUICKTIPPS_DELETE.replace('{quicktipp_id}', String(data.quicktippId))
        )
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich gelöscht", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    /**
     * =======================================================
     * ================ CAMPAIGN QUICKTIPPS ==================
     * =======================================================
     */

    createOnCampaign({commit, dispatch}, data: { quicktippData: QuicktippModel; campaignId: number }) {
        api.post(ROUTES.CAMPAIGNS_QUICKTIPPS_CREATE_NEW.replace('{campaign_id}', String(data.campaignId)), data.quicktippData)
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich angelegt", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("campaigns/getAllQuicktipps", {campaignId: data.campaignId}, {root: true})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    updateOnCampaign({
                         commit,
                         dispatch
                     }, data: { campaignId: number; quicktippId: number; quicktippData: QuicktippModel }) {
        api.put(ROUTES.CAMPAIGNS_QUICKTIPPS_UPDATE
            .replace('{campaign_id}', String(data.campaignId))
            .replace('{quicktipp_id}', String(data.quicktippId)), data.quicktippData)
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich bearbeitet", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("campaigns/getAllQuicktipps", {campaignId: data.campaignId}, {root: true})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    deleteOnCampaign({commit, dispatch}, data: { campaignId: number; quicktippId: number }) {
        api.delete(ROUTES.CAMPAIGNS_QUICKTIPPS_DELETE
            .replace('{campaign_id}', String(data.campaignId))
            .replace('{quicktipp_id}', String(data.quicktippId))
        )
            .then((response: AxiosResponse) => {
                commit('info/success', "Quicktipp erfolgreich gelöscht", {root: true});

                //Remove quicktipps Dialog
                commit('dismissQuicktippsDialog', state);

                //reload all quicktipps
                dispatch("campaigns/getAllQuicktipps", {campaignId: data.campaignId}, {root: true})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<QuicktippsState> = {
    setQuicktipps(state, quicktipps: Array<QuicktippModel>) {
        state.quicktipps = quicktipps
    },
    showQuicktippsDialog(state, data: {
        quicktipp: QuicktippModel | null;
        isNew: boolean;
        context: string;
        campaignId: number
    }) {
        state.dialogState.quicktipp = data.quicktipp;
        state.dialogState.isNew = data.isNew;
        state.dialogState.visible = true;
        state.dialogState.contextIdentifier = data.context;
        state.dialogState.campaignId = data.campaignId;
    },
    dismissQuicktippsDialog(state) {
        state.dialogState.visible = false;
        state.dialogState.quicktipp = null;
        state.dialogState.isNew = false;
        state.dialogState.contextIdentifier = "";
        state.dialogState.campaignId = null
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
};

export const quicktipps: Module<QuicktippsState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};
