export function devPrint(item: any) {
    console.log('DEBUG', item)
}

export async function toBase64(file: any): Promise<string | null> {
    const parsed = await parseData(file)
    if (parsed && (typeof parsed === "string")) {
        return Promise.resolve(btoa(parsed))
    }
    return Promise.resolve(null)
}

const parseData = (
    file: any
): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
