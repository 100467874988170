import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {ROUTES} from "@/constants/routes";
import {SwitchDatesModel, SwitchDatesState} from "@/stores/switchdates/types";

const getDefaultState = () => {
    return {
        switchDates: {
            switch_time: null,
            switch_time_first_drawing: null,
            fee_update: null,
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<SwitchDatesState, RootState> = {
    getAll({commit}, data) {
        api.get(ROUTES.SWITCHDATES_GET_ALL.replace('{ges_nr}', String(data)))
            .then((response: AxiosResponse) => {
                commit('setSwitchDates', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    postEjSwitch({commit, dispatch}, data: { switch_time: number; gesNr: number }) {
        api.post(ROUTES.SWITCHDATES_POST_EJ_SWITCH, {switch_time: data.switch_time})
            .then((response: AxiosResponse) => {
                commit('info/success', "Erfolgreich aktualisiert", {root: true});

                //reload all switchdates
                dispatch("getAll", data.gesNr)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    postEjFirstDrawNew({commit, dispatch}, data: { switch_time: number; gesNr: number }) {
        api.post(ROUTES.SWITCHDATES_POST_EJ_FIRST_DRAW_NEW, {switch_time: data.switch_time})
            .then((response: AxiosResponse) => {
                commit('info/success', "Erfolgreich aktualisiert", {root: true});

                // reload all switchdates
                dispatch("getAll", data.gesNr)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    postFeeUpdate({commit, dispatch}, data: { switch_time: number; gesNr: number }) {
        api.post(ROUTES.SWITCHDATES_POST_FEE_UPDATE, {switch_time: data.switch_time})
            .then((response: AxiosResponse) => {
                commit('info/success', "Erfolgreich aktualisiert", {root: true});

                // reload all switchdates
                dispatch("getAll", data.gesNr)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<SwitchDatesState> = {
    setSwitchDates(state, dates: SwitchDatesModel) {
        state.switchDates = dates;
    },
    // setEjSwitch(state, millis: number) {
    //     state.switchDates.switch_time = millis;
    // },
    // setEjFirstDrawNew(state, millis: number) {
    //     state.switchDates.switch_time_first_drawing = millis;
    // }
};

export const switchdates: Module<SwitchDatesState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};
