
import {Component, Vue, Watch} from "vue-property-decorator";
import HomeDrawer from "@/components/HomeDrawer.vue";
import {NAMES} from "@/constants/names";
import {namespace} from "vuex-class";
import {OrgConfig, User} from "@/stores/auth/types";
import {ROLES} from "@/enum/Roles";
import {Organization} from "@/enum/Organizations";
import {variant, version} from "@/api/base";
import {VALUES} from "@/constants/values";

const authModule = namespace('auth');
const infoModule = namespace('info');

@Component({
  components: {
    HomeDrawer
  },
})
export default class HomeAppBar extends Vue {
  @authModule.Mutation('resetState') resetState!: Function;
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('user') user!: User;
  @infoModule.State('loading') loading!: boolean;

  loadActive = false;
  drawer = null;
  selectedMoreItem = false

  get orgAsset() {
    return require('@/assets/logo_lotto_brandenburg.png');
  }

  get appIcon() {
    if (variant == "live") {
      return require('@/assets/appicons/service/live/bb.png');
    } else {
      return require('@/assets/appicons/service/test/bb.png');
    }
  }

  get items() {
    return this.prepareNavigationItems(false)
  }

  get moreItems() {
    return this.prepareNavigationItems(true)
  }

  // returns string array of page names
  prepareNavigationItems(forOverflow: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tabs: Array<any> = [];

    // Note: Screensaver has no config field as it is an exclusive page in this project
    // (which was split from project for many organizations)
    tabs.push(NAMES.SCREENSAVER)
    if (this.config.has_campaign_management) {
      tabs.push(NAMES.CAMPAIGNS)
    }
    if (this.config.has_pdf_management) {
      tabs.push(NAMES.DOCUMENTS)
    }
    if (this.config.has_lot_management) {
      tabs.push(NAMES.TICKETS)
    }
    if (this.config.has_simple_quicktipps) {
      tabs.push(NAMES.QUICKTIPPS)
    }
    if (this.config.has_pdf_management && this.user.role.identifier == ROLES.ADMIN.identifier) {
      tabs.push(NAMES.REPORTS)
    }
    // site has currently no use, never show regardless of login config
    // if (this.config.has_switch_management) {
    //   tabs.push(NAMES.SWITCHDATES)
    // }
    //Show user always on the most right
    if (this.config.has_user_management && this.user.role.identifier != ROLES.MARKETING.identifier) {
      tabs.push(NAMES.USERS)
    }

    if (forOverflow) {
      if (tabs.length >= VALUES.MAX_TABS_IN_NAVIGATION) {
        tabs = tabs.slice(VALUES.MAX_TABS_IN_NAVIGATION, tabs.length);
      } else {
        tabs = []
      }
    } else {
      if (tabs.length >= VALUES.MAX_TABS_IN_NAVIGATION) {
        tabs = tabs.slice(0, VALUES.MAX_TABS_IN_NAVIGATION);
      }
    }

    return tabs
  }

  navigate(item: string) {
    this.selectedMoreItem = true
    this.$router.push(item.toLocaleLowerCase());
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  logout() {
    this.resetState()
  }

  translate(pageName: string) {
    switch (pageName) {
      case NAMES.CAMPAIGNS:
        return "Kampagnen";
      case NAMES.USERS:
        return "Nutzer";
      case NAMES.DOCUMENTS:
        return "Dokumente";
      case NAMES.TICKETS:
        return "Lose";
      case NAMES.QUICKTIPPS:
        return "Quicktipps";
      case NAMES.REPORTS:
        return "Absturzberichte";
      case NAMES.SWITCHDATES:
        return "Umstellungsdaten";
      case NAMES.SCREENSAVER:
        return "Bildschirmschoner";
    }
  }

  getFrontendVersion(): string {
    return version
  }
}
