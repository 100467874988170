import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from './auth'
import {campaigns} from './campaigns'
import {info} from "@/stores/info";
import {documents} from "@/stores/documents";
import {tickets} from "@/stores/tickets";
import {users} from "@/stores/users"
import {quicktipps} from "@/stores/quicktipps";
import {reports} from "@/stores/reports"
import {switchdates} from "@/stores/switchdates";
import Cookies from "js-cookie";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            getState: (key) => Cookies.get(key),
            // remember for 7 Days (resets on new write?)
            setState: (key, state) => Cookies.set(key, state, {expires: 7, secure: true})
        })
    ],
    modules: {
        auth,
        campaigns,
        info,
        documents,
        tickets,
        users,
        quicktipps,
        reports,
        switchdates
    }
})
