export const ERRORS = {
    INVALID_TOKEN: {code: 1002, message: "Authentifikations Token ist ausgelaufen. Bitte loggen Sie sich erneut ein"},
    // INVALID_LOGIN: {code: 1003, message: "Logindaten falsch"},
    UNKNOWN: {
        code: -1,
        message: "Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie ihre Internetverbindung oder wenden sich an den Betreiber."
    }
};

export interface EnumError {
    code: number;
    message: string;
}

export function getErrorMessageForCode(code: number | undefined): string | null {
    switch (code) {
        case ERRORS.INVALID_TOKEN.code:
            return ERRORS.INVALID_TOKEN.message;
        // case ERRORS.INVALID_LOGIN.code:
        //   return ERRORS.INVALID_LOGIN.message;
        default:
            return null
    }
}

export function checkForNetworkError(error: any): string {
    if (error.toString() === "Error: Network Error") {
        return ERRORS.UNKNOWN.message
    } else {
        return error
    }
}
