
import Vue from "vue";
import {Component} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {ReportDialogModel} from "@/stores/reports/types";

const reportsModule = namespace('reports');

@Component({
  name: 'ReportDialog'
})
export default class ReportDialog extends Vue {
  @reportsModule.State('dialogState') state!: ReportDialogModel;
  @reportsModule.Mutation('dismissReportDialog') cancel!: Function;

  get type() {
    if (this.state.report && this.state.report.type) {
      return this.state.report.type
    }
    return ''
  }

  get content() {
    if (this.state.report && this.state.report.content) {
      return this.state.report.content
    }
    return ''
  }

  onCancel() {
    this.cancel();
  }
}
