export const PATHS = {
    LOGIN: '/',
    CAMPAIGNS: '/campaigns',
    USERS: '/users',
    DOCUMENTS: '/documents',
    TICKETS: '/lose',
    QUICKTIPPS: '/quicktipps',
    REPORTS: '/reports',
    SWITCHDATES: '/switchdates',
    SCREENSAVER: '/screensaver'
};

export function getLandingPageForOrganizationId(id: number | undefined): string {
    return PATHS.CAMPAIGNS;
}
