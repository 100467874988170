
import {Component, Model, Prop} from "vue-property-decorator";
import Vue from "vue";
import {AcceptFlag} from "@/enum/AcceptFlag";

@Component({
  name: 'AppFileInput'
})
export default class AppFileInput extends Vue {
  @Model('input', {type: File}) readonly file!: File | null;

  @Prop({type: String, required: true, default: ''}) readonly label!: string;
  @Prop({type: String, default: AcceptFlag.ACCEPT_IMAGE_AND_PDF}) readonly accept!: string;
  @Prop({type: Boolean, default: false}) readonly required!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;

  rules = {
    required: (v: {}) => !!v || 'Bitte wählen Sie eine Datei aus'
  };

  onChanged(file: any) {
    this.$emit('input', file)
  }
}
